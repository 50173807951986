import { createColumnHelper } from '@tanstack/react-table'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { usePreferences } from 'context/preferences/PreferencesContext'

import { useMemo } from 'react'
import { EMAILS_COLUMNS } from './emailsColumnInfo'
import { EmailMessage } from 'models/emailIntegration'
import { OverflownText } from 'components/OverflownText'

export const useEmailsTableColumns = () => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}

  const columnHelper = createColumnHelper<EmailMessage>()
  const contactColumnHelper = createTableColumnHelper({
    columns: EMAILS_COLUMNS,
    tooltips,
  })

  const cols = useMemo(
    () => [
      columnHelper.accessor('outbound', {
        ...contactColumnHelper.retrieveColumn('outbound', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        cell: (info) => {
          const value = info.getValue()

          return <span>{value ? 'Outbound' : 'Inbound'}</span>
        },
      }),
      columnHelper.accessor('related_contacts', {
        ...contactColumnHelper.retrieveColumn('related_contacts'),
        cell: (info) => {
          const value = info.getValue()
          const capitalize = (s: string) =>
            s.charAt(0).toUpperCase() + s.slice(1)
          return value
            ?.map(
              (contact) =>
                `${capitalize(contact.first_name)} ${capitalize(contact.last_name)}`
            )
            .join(', ')
        },
      }),
      columnHelper.accessor('subject', {
        ...contactColumnHelper.retrieveColumn('subject'),
        size: 300,
      }),
      columnHelper.accessor('snippet', {
        ...contactColumnHelper.retrieveColumn('snippet'),
        size: 300,
        cell: (info) => {
          const value = info.getValue()
          return <OverflownText maxLines={2}>{value || '-'}</OverflownText>
        },
      }),
      columnHelper.accessor('date', {
        ...contactColumnHelper.retrieveColumn('date'),
        size: 200,
        cell: (info) => {
          const date = info.getValue()
          return date ? new Date(date).toLocaleString() : '-'
        },
      }),
    ],
    [preferences, tooltips]
  )

  return cols
}
