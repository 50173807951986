import styled from 'styled-components/macro'
import { CSSProperties } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

interface TabsProps<T> {
  tabs: {
    key: string
    title: string | React.JSX.Element
    dropdownItems: DropdownItem<T>[]
  }[]
  selectedTabKey: string
  selectedDropdownItemKey: string
  setSelectedTab: (tab: T) => void
  style?: CSSProperties
  isSmall?: boolean
}

interface DropdownItem<T> {
  key: string
  title: string
  value: T
}

export function TabsWithDropdown<T>({
  tabs,
  selectedTabKey,
  selectedDropdownItemKey,
  setSelectedTab,
  style,
  isSmall,
}: TabsProps<T>) {
  return (
    <TabContainer style={style}>
      {tabs.map(({ key, title, dropdownItems }) => (
        <CustomDropdownButton
          key={String(key)}
          title={title}
          $isActive={selectedTabKey === key ? 1 : 0}
          $isSmall={isSmall ? 1 : 0}
          onSelect={(eventKey: string) => {
            const item = dropdownItems.find((item) => item.key == eventKey)
            if (!item) return

            setSelectedTab(item.value)
          }}
          drop="down"
          align="start"
        >
          {dropdownItems.map((item) => (
            <Dropdown.Item
              key={item.key as unknown as string}
              eventKey={item.key}
              active={selectedDropdownItemKey === item.key}
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </CustomDropdownButton>
      ))}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  gap: 25px;
  padding: 0 124px;
  background: #2c6b4e;
  color: white;
`

const CustomDropdownButton = styled(DropdownButton)<{
  $isActive: number
  $isSmall: number
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${(props) => (props.isSmall === 1 ? '13px' : '14px')};
  font-style: normal;
  font-weight: ${(props) =>
    props.isActive === 1 ? (props.isSmall ? '400' : '500') : '300'};
  text-decoration: ${(props) => (props.isActive === 1 ? 'underline' : 'none')};
  text-underline-offset: 4px;
  line-height: 20px;
  border-bottom: 2px solid transparent;
  padding: 6px 12px;
  cursor: pointer;

  &.selected {
    border-bottom: 2px solid #3c5e19;
  }

  &:hover {
    text-decoration: underline;
    box-shadow: none;
    border: none;
  }

  .dropdown-toggle {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    border: none;
    background: none;
    padding: 0;
    &:hover {
      text-decoration: underline;
      border: none;
      box-shadow: none;
    }
    &:active {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
    color: black;
    text-decoration: underline;
    font-weight: ${(props) => (props.isSmall ? '400' : '500')};
  }
  .dropdown-item:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  .dropdown-menu {
    min-width: auto;
  }

  &:active,
  &:focus {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
  }
`
