import { forwardRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Control, FieldValues, Path, useController } from 'react-hook-form'
import { Country, DefaultInputComponentProps } from 'react-phone-number-input'
import Input, { getCountries } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'
import { SelectOption } from '../CreatableSelect'
import { ErrorLabel } from '../ErrorLabel'
import ReactSelectWrapper from '../ReactSelectWrapper'
import { TextInput } from '../TextInput'

type CustomInputProps = DefaultInputComponentProps &
  React.RefAttributes<HTMLInputElement> & {
    placeholder?: string
  }

const CustomInput = forwardRef(
  (props: CustomInputProps, ref: React.ForwardedRef<HTMLInputElement>) => (
    <Form.Control
      {...props}
      placeholder={props.placeholder ?? 'Phone number'}
      ref={ref}
    />
  )
)
CustomInput.displayName = 'CustomInput'

const CountrySelect = ({
  value,
  onChange,
  ...rest
}: {
  value: Country
  onChange: (value: Country) => void
  labels?: Record<Country, string>
}) => {
  const options: SelectOption[] = getCountries().map((country) => ({
    label: `${country}`,
    value: country,
  }))

  return (
    <ReactSelectWrapper
      {...rest}
      options={options}
      value={options.find((option) => option.value === value)}
      onChange={(option) =>
        onChange((option as SelectOption)?.value as Country)
      }
      styles={{
        container: (provided) => ({
          ...provided,
          minWidth: 100,
        }),
      }}
    />
  )
}

export function PhoneInput<T extends FieldValues>({
  name,
  label,
  control,
  extensionName,
  optional = false,
  style,
}: {
  control: Control<T>
  name: Path<T>
  extensionName?: Path<T>
  label?: string
  optional?: boolean
  style?: React.CSSProperties
}) {
  const {
    field: { ref, onChange, value },
    fieldState: { error },
  } = useController({ name, control })
  const [country, setCountry] = useState<Country>('US')

  const handleCountryChange = (country: Country) => {
    setCountry(country)
    onChange(null)
  }

  const handlePhoneChange = (value: string | undefined) => {
    onChange(value || null)
  }

  return (
    <div>
      {label && (
        <label
          className="mt-3 mb-1 text-base font-medium text-gray-800 select-none"
          htmlFor={name}
        >
          {label}
          {optional && (
            <span className={'text-sm italic font-normal'}> - optional</span>
          )}
        </label>
      )}
      <div className="flex gap-2">
        <div className="flex-1">
          <div className="flex gap-2">
            <CountrySelect
              labels={en}
              value={country}
              onChange={handleCountryChange}
            />
            <div className="flex-1">
              <Input
                country={country}
                international
                withCountryCallingCode
                ref={ref}
                value={value}
                onChange={handlePhoneChange}
                inputComponent={CustomInput}
                className={error?.message ? 'input-error' : ''}
                style={style}
              />
            </div>
          </div>
          {error?.message && <ErrorLabel message={error?.message} />}
        </div>
        {extensionName && (
          <div className="">
            <ExtensionInput control={control} name={extensionName} />
          </div>
        )}
      </div>
    </div>
  )
}

export function ExtensionInput<T extends FieldValues>({
  name,
  control,
}: {
  control: Control<T>
  name: Path<T>
}) {
  return (
    <TextInput
      className="flex-1"
      noMargin
      control={control}
      name={name}
      placeholder="-Ext"
      optional
    />
  )
}
