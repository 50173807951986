import { useState } from 'react'
import { queryClient } from '../../services/queryClient'
import { formatInteger } from '../../utils/formatting'
import { CollapsibleSection } from '../UI/CollapsibleSection/CollapsibleSection'
import { EmailsTable } from 'components/Tables/EmailsTable/EmailsTable'

const EmailsTableSection = ({
  forChainProxyId,
}: {
  forChainProxyId: number
}) => {
  const [emailsCount, setEmailsCount] = useState<number>(0)
  const TABLE_KEY = `company-emails-table-${forChainProxyId}`

  const refetchEmailsTable = () => {
    queryClient.refetchQueries({ queryKey: [TABLE_KEY] })
  }

  return (
    <>
      <CollapsibleSection
        forceClose={emailsCount < 1}
        summary={
          <div className={'flex justify-between items-center w-full'}>
            <div>
              Emails{' '}
              <span className={'font-thin'}>
                ({formatInteger(emailsCount)})
              </span>
            </div>
          </div>
        }
      >
        <EmailsTable
          tableKey={TABLE_KEY}
          setTotalRowsCount={setEmailsCount}
          handleContactUpdated={refetchEmailsTable}
          disableUrlPagination
          disableExportButton
          forChainProxyId={forChainProxyId}
        />
      </CollapsibleSection>
    </>
  )
}

export default EmailsTableSection
