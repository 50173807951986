import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { FilterIdentifier } from 'models/saved_view'
import { EMAILS_COLUMNS } from './emailsColumnInfo'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'

export const CONTACT_SOURCE_OPTIONS = [
  { value: '0', label: 'Internal' },
  { value: '1', label: 'First Bite' },
]

export default function EmailsFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const emailsFilterStore = filterStoreRepo.getStore(
    filterIdentifier as FilterIdentifier
  )

  const emailsFilterHelper = createFilterColumnHelper({
    columns: EMAILS_COLUMNS,
  })

  return (
    <FilterModal store={emailsFilterStore} identifier={filterIdentifier}>
      <FilterGroup title="Email" className="lg:grid-cols-2">
        <SelectFilter
          {...emailsFilterHelper.retrieveProps('outbound')}
          filterStore={emailsFilterStore}
          options={[
            {
              label: 'All',
              value: '-1',
            },
            {
              label: 'Inbound',
              value: '0',
            },
            {
              label: 'Outbound',
              value: '1',
            },
          ]}
        />
        <DateRangeFilter
          {...emailsFilterHelper.retrieveProps('date')}
          filterStore={emailsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
