import { K12District } from 'models/k12'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

export const K12_COLUMNS = {
  lea_name: {
    header: 'School District',
    group: 'Opportunity Attributes',
  },
  address: {
    header: 'Address',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_full_address',
  },
  zipcode: {
    header: 'ZIP code',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_zipcode',
  },
  lcity: {
    header: 'City',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_city',
  },
  lstate: {
    header: 'State',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_full_address',
  },
  county: {
    header: 'County',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_county',
  },
  locale: {
    header: 'Locale',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_locale',
  },
  operational_schools: {
    header: 'School Count',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_schools_count',
  },
  gslo: {
    header: 'Lowest Grade',
    group: 'School Insights',
    tooltipName: 'opps_lowest_grade',
  },
  gshi: {
    header: 'Highest Grade',
    group: 'School Insights',
    tooltipName: 'opps_highest_grade',
  },
  student_count: {
    header: 'Students',
    group: 'School Insights',
    tooltipName: 'opps_students',
  },
  teachers_count: {
    header: 'Teachers',
    group: 'School Insights',
    tooltipName: 'opps_teachers',
  },
  phone: {
    header: 'Phone',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_phone',
  },
  revenue: {
    header: 'Revenue',
    group: 'School Insights',
    tooltipName: 'opps_k12_revenue',
  },
  reduced_lunch: {
    header: 'Reduced Lunch',
    group: 'Dining Services',
    tooltipName: 'opps_reduced_lunch_count',
  },
  free_lunch: {
    header: 'Free Lunch',
    group: 'Dining Services',
    tooltipName: 'opps_free_lunch_count',
  },
  estimated_meals_per_day: {
    header: 'Estimated Meals Per Day',
    group: 'Dining Services',
    tooltipName: 'opps_k12_meals_day',
  },
  food_service_expenditures: {
    header: 'Food Service Expenditures',
    group: 'Dining Services',
    tooltipName: 'opps_k12_fs_expenditures',
  },
  total_supplemented_lunch: {
    header: 'Total Supplemented Lunch',
    group: 'Dining Services',
    tooltipName: 'opps_supplemented_lunch_count',
  },
  total_supplemented_lunch_perc: {
    header: 'Total Supplemented Lunch (%)',
    group: 'Dining Services',
    tooltipName: 'opps_supplemented_lunch_percent',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_google_place_url',
  },
  uncategorized_lunch: {
    header: 'Uncat. Supplemented Lunch',
    group: 'Dining Services',
    tooltipName: 'opps_uncat_lunch_count',
  },
  free_lunch_perc: {
    header: 'Free Lunch (%)',
    group: 'Dining Services',
    tooltipName: 'opps_free_lunch_percent',
  },
  reduced_lunch_perc: {
    header: 'Reduced Lunch (%)',
    group: 'Dining Services',
    tooltipName: 'opps_reduced_lunch_percent',
  },
  uncategorized_lunch_perc: {
    header: 'Uncat. Supplemented Lunch (%)',
    group: 'Dining Services',
    tooltipName: 'opps_uncat_lunch_percent',
  },
} satisfies Partial<Record<keyof K12District, DataTypeColumn>>
