import { createColumnHelper } from '@tanstack/react-table'
import { getCommonContactCompanyColumns } from 'components/DataExpansionComponents/Common/CommonTable/CommonColumns'
import { Anchor } from 'components/UI/Anchor'
import { usePreferences } from 'context/preferences/PreferencesContext'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import { integerFormatterOrFallback } from 'utils/formatting'
import { ContactCompanyType } from '../../../../models/contact_companies'
import { NursingHomeChainProxy } from '../../../../models/nursinghome'
import { DataTableColumnSort } from '../../../DataTable/DataTableSorting/DataTableSortingStore'
import { FbLink } from '../../../FbUI/FbLink'
import { NURSING_HOME_COLUMNS } from '../nursingHomeColumnsInfo'
import { createTableColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export const useNursingHomesTableCols = ({
  sorting,
}: {
  sorting?: DataTableColumnSort[]
}) => {
  const { preferences } = usePreferences()
  const tooltips: Record<string, unknown> = preferences?.tooltips || {}
  const featureFlag = featureFlagService()
  const columnHelper = createColumnHelper<NursingHomeChainProxy>()
  const nursingHomeColumnHelper = createTableColumnHelper({
    columns: NURSING_HOME_COLUMNS,
    tooltips,
  })

  const columns = useMemo(() => {
    let cols = [
      columnHelper.accessor('nursinghome.provider_name', {
        ...nursingHomeColumnHelper.retrieveColumn('provider_name', {
          meta: {
            isEditable: false,
            isFirstColumn: true,
          },
        }),
        header: () => null,
        size: 250,
        cell: (info) => {
          const name = info.getValue()
          let link = ''
          if (info.row.original?.nursinghome?.id) {
            link = `/nursinghome/${
              preferences?.company_type_prefix_map[
                ContactCompanyType.NursingHome
              ]
            }${info.row.original.nursinghome?.id}`
          } else {
            link = `/company/${
              preferences?.company_type_prefix_map[
                ContactCompanyType.UserGenerated
              ]
            }${info.row.original?.id}`
          }
          return (
            <FbLink to={link} target="_blank">
              {name}
            </FbLink>
          )
        },
      }),
      columnHelper.accessor('nursinghome.address', {
        ...nursingHomeColumnHelper.retrieveColumn('address'),
        size: 250,
      }),
      columnHelper.accessor('nursinghome.city', {
        ...nursingHomeColumnHelper.retrieveColumn('city'),
        size: 150,
      }),
      columnHelper.accessor('nursinghome.state', {
        ...nursingHomeColumnHelper.retrieveColumn('state'),
        size: 100,
      }),
      columnHelper.accessor('nursinghome.zip_code', {
        ...nursingHomeColumnHelper.retrieveColumn('zip_code'),
        size: 100,
      }),
      columnHelper.accessor('nursinghome.google_place_url', {
        ...nursingHomeColumnHelper.retrieveColumn('google_place_url'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              View
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('nursinghome.projected_meals_per_day', {
        ...nursingHomeColumnHelper.retrieveColumn('projected_meals_per_day'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.number_of_certified_beds', {
        ...nursingHomeColumnHelper.retrieveColumn('number_of_certified_beds'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.average_number_of_residents_per_day', {
        ...nursingHomeColumnHelper.retrieveColumn(
          'average_number_of_residents_per_day'
        ),
        size: 150,
        cell: (info) => {
          const val = info.getValue()
          return val != null ? val.toString() : '-'
        },
      }),
      columnHelper.accessor('nursinghome.overall_rating', {
        ...nursingHomeColumnHelper.retrieveColumn('overall_rating'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.health_inspection_rating', {
        ...nursingHomeColumnHelper.retrieveColumn('health_inspection_rating'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.qm_rating', {
        ...nursingHomeColumnHelper.retrieveColumn('qm_rating'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.staffing_rating', {
        ...nursingHomeColumnHelper.retrieveColumn('staffing_rating'),
        size: 150,
        cell: (info) => {
          return integerFormatterOrFallback(info.getValue()) || '-'
        },
      }),
      columnHelper.accessor('nursinghome.affiliated_entity_name', {
        ...nursingHomeColumnHelper.retrieveColumn('affiliated_entity_name'),
        size: 200,
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('nursinghome.domain', {
        ...nursingHomeColumnHelper.retrieveColumn('domain'),
        size: 200,
        cell: (info) => {
          const domain = info.getValue()
          if (!domain) {
            return '-'
          }
          return (
            <Anchor href={'//' + domain} target="_blank">
              {domain}
            </Anchor>
          )
        },
      }),
      columnHelper.accessor('nursinghome.website_url', {
        ...nursingHomeColumnHelper.retrieveColumn('website_url'),
        size: 150,
        cell: (info) => {
          const value = info.getValue()
          if (!value) {
            return '-'
          }
          return (
            <Anchor href={value} target="_blank">
              Visit
            </Anchor>
          )
        },
      }),
      ...getCommonContactCompanyColumns(columnHelper, sorting),
      columnHelper.accessor('nursinghome.ownership_type', {
        ...nursingHomeColumnHelper.retrieveColumn('ownership_type'),
        size: 150,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor('nursinghome.provider_type', {
        ...nursingHomeColumnHelper.retrieveColumn('provider_type'),
        size: 150,
        cell: (info) => {
          return info.getValue() || '-'
        },
      }),
      columnHelper.accessor(
        'nursinghome.continuing_care_retirement_community',
        {
          ...nursingHomeColumnHelper.retrieveColumn(
            'continuing_care_retirement_community'
          ),
          size: 150,
          cell: (info) => {
            const value = info.getValue()
            if (value === 'Y') return 'Yes'
            if (value === 'N') return 'No'
            return '-'
          },
        }
      ),
    ]
    if (!featureFlag.enableCampaigns) {
      cols = cols.filter((col) => !col.id?.includes('campaigns'))
    }
    return cols
  }, [columnHelper, sorting, featureFlag, nursingHomeColumnHelper, preferences])
  return columns
}
