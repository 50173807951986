import { format } from 'date-fns'
import { RebateClaimDetail } from './tradespend_api'
import { BiArrowBack, BiChevronDown, BiLinkExternal } from 'react-icons/bi'
import styled from 'styled-components'
import { useState } from 'react'

interface ClaimInvoicesViewProps {
  claim: RebateClaimDetail
  onBack: () => void
  productNameMap: Record<string, string>
}

export function ClaimInvoicesView({
  claim,
  onBack,
  productNameMap,
}: ClaimInvoicesViewProps) {
  const [expandedInvoices, setExpandedInvoices] = useState<number[]>([])

  const toggleExpand = (invoiceId: number) => {
    setExpandedInvoices((prev) =>
      prev.includes(invoiceId)
        ? prev.filter((id) => id !== invoiceId)
        : [...prev, invoiceId]
    )
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
        >
          <BiArrowBack />
          Back to Claim #{claim.id} Details
        </button>
      </div>

      <div className="space-y-4">
        {claim.invoices.map((invoice) => {
          const isExpanded = expandedInvoices.includes(invoice.id)
          return (
            <InvoiceCard key={invoice.id}>
              <InvoiceHeader onClick={() => toggleExpand(invoice.id)}>
                <div className="flex-1">
                  <InvoiceTitle>
                    {invoice.invoice_reference_id || `Invoice #${invoice.id}`}
                  </InvoiceTitle>
                  <InvoiceDate>
                    Uploaded on{' '}
                    {format(new Date(invoice.created), 'MMM d, yyyy h:mm a')}
                  </InvoiceDate>
                </div>
                <div className="flex items-center gap-4">
                  {invoice.file_url && (
                    <DownloadButton
                      onClick={(e) => {
                        e.stopPropagation()
                        if (invoice.file_url)
                          window.open(invoice.file_url, '_blank')
                      }}
                    >
                      <BiLinkExternal size={16} />
                      View Invoice
                    </DownloadButton>
                  )}
                  <ExpandIcon $isExpanded={isExpanded}>
                    <BiChevronDown size={24} />
                  </ExpandIcon>
                </div>
              </InvoiceHeader>

              {isExpanded && (
                <InvoiceContent>
                  <ContentSection>
                    <SectionTitle>Approved Products</SectionTitle>
                    <ProductsGrid>
                      {Object.entries(invoice.approval_info || {}).map(
                        ([productId, info]) => (
                          <ProductCard key={productId}>
                            <div className="flex justify-between items-start mb-3">
                              <div>
                                <ProductTitle>
                                  {productNameMap[productId] ||
                                    `Product ID: ${productId}`}
                                </ProductTitle>
                                <div className="text-sm text-gray-500">
                                  {info.num_units} units approved
                                </div>
                              </div>
                            </div>
                            <IdentifiersList>
                              <IdentifiersLabel>
                                Identifiers Found:
                              </IdentifiersLabel>
                              {info.product_identifiers.map(
                                (identifier, idx) => (
                                  <IdentifierTag key={idx}>
                                    {identifier.product_identifier_type}:{' '}
                                    {identifier.product_identifier}
                                  </IdentifierTag>
                                )
                              )}
                            </IdentifiersList>
                          </ProductCard>
                        )
                      )}
                    </ProductsGrid>
                  </ContentSection>

                  {invoice.parsed_data &&
                    Object.keys(invoice.parsed_data).length > 0 && (
                      <ContentSection>
                        <SectionTitle>Parsed Data</SectionTitle>
                        <pre className="bg-gray-50 p-4 rounded-md overflow-x-auto">
                          {JSON.stringify(invoice.parsed_data, null, 2)}
                        </pre>
                      </ContentSection>
                    )}
                </InvoiceContent>
              )}
            </InvoiceCard>
          )
        })}
      </div>
    </div>
  )
}

const InvoiceCard = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: white;
  overflow: hidden;
`

const InvoiceHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background: #f9fafb;
  }
`

const ExpandIcon = styled.div<{ $isExpanded: boolean }>`
  transform: rotate(${(props) => (props.$isExpanded ? '180deg' : '0deg')});
  transition: transform 0.2s ease;
  color: #6b7280;
`

const InvoiceContent = styled.div`
  border-top: 1px solid #e5e7eb;
  padding: 16px;
`

const ContentSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 12px;
`

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
`

const InvoiceTitle = styled.div`
  font-weight: 600;
  color: #171c26;
`

const InvoiceDate = styled.div`
  font-size: 14px;
  color: #687182;
`

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: #374151;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: #111827;
  }
`

const ProductCard = styled.div`
  padding: 16px;
  background: #f9fafb;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
`

const ProductTitle = styled.div`
  font-weight: 600;
  color: #111827;
  margin-bottom: 4px;
`

const IdentifiersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const IdentifierTag = styled.span`
  background: #e5e7eb;
  color: #374151;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
`

const IdentifiersLabel = styled.div`
  width: 100%;
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 4px;
`
