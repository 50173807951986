import { FBPage } from 'components/FbUI/Page/FBPage'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from 'components/FbUI/Page/styles'
import { Tabs } from 'components/FbUI/Tabs'
import { RebateDashboard } from 'features/tradespend/RebateDashboard'
import { RebateOffersTable } from 'features/tradespend/RebateOffersTable'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNavbarStore } from 'components/Layout/navbar-store'
import { CreateRebateOfferModal } from 'features/tradespend/CreateRebateOfferModal'
import { RebateClaimsTable } from 'features/tradespend/RebateClaimsTable'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 95px)'

export function Rebates() {
  const navigate = useNavigate()
  const location = useLocation()
  const store = useNavbarStore()
  const selectedTab = location.pathname.split('/')[2] || 'dashboard'

  useEffect(() => {
    if (!location.pathname.includes('/rebates/')) {
      const url = '/rebates/dashboard'
      navigate(url)
      store.setLastRebateVisited(url)
    }
  }, [location.pathname, navigate, store])

  const tabs = [
    { key: 'dashboard', title: 'Dashboard' },
    { key: 'offers', title: 'Offers' },
    { key: 'claims', title: 'Claims' },
  ]

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Rebates</FBPageTitle>
        </FBPageTitleRow>
        <div className="flex gap-3 mx-6">
          <CreateRebateOfferModal />
        </div>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/rebates/${value}`
            navigate(url)
            store.setLastRebateVisited(url)
          }}
          tabStyle={{ width: '125px' }}
        />
      </FBPageInnerTabsContainer>

      {selectedTab === 'dashboard' && (
        <div className="flex-1">
          <RebateDashboard />
        </div>
      )}
      {selectedTab === 'offers' && (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <RebateOffersTable />
        </FBPageTableContainer>
      )}
      {selectedTab === 'claims' && (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <RebateClaimsTable />
        </FBPageTableContainer>
      )}
    </FBPage>
  )
}
