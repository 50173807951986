import { DataTableProvider } from 'components/DataTable'

import { ContactResponse } from 'models/contacts'

import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { FilterIdentifier } from '../../../../models/saved_view'
import { IColumnVisibility } from '../../../DataTable/types'
import { UniversitiesFilterset } from './UniversitiesFilterset'
import { useUniversitiesTableCols } from './universitiesTableColumns'

import { DECommonTable } from 'components/DataExpansionComponents/Common/CommonTable/CommonTable'
import UniversityExportModal from 'components/Modals/ExportModal/UniversityExportModal'
import { dataTableSortingStoreRepo } from '../../../DataTable/DataTableSorting/DataTableSortingStore'

type SortableFields = (keyof ContactResponse | string)[]

const sortableFields: SortableFields = [
  'institution_name',
  'total_students_enrolled',
  'note_count',
  'contact_count',
  'deal_count',
  'address',
  'city',
  'state_abbr',
  'zipcode',
  'domain',
  'dormitory_capacity',
  'meal_plan_provided',
  'total_enrollment',
  'food_management_company',
  'food_management_company_parent',
  'estimated_meals_per_day',
  'carnegie_classification_2021_size_setting_size',
  'carnegie_classification_2021_size_setting_year',
  'carnegie_classification_2021_size_setting_student_composition',
  'sales_stages',
]

interface UniversitiesTableProps {
  setTotalRowsCount?: (count: number) => void
  pageParamName?: string
  baseFilters?: Record<string, any>
  tableKey: string
  filterIdentifierModifier?: FilterIdentifier
  hideCreateCompanyButton?: boolean
}

function UniversitiesTableComponent(props: UniversitiesTableProps) {
  const api = apiService()
  const { tableKey, ...rest } = props
  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const columns = useUniversitiesTableCols({ sorting })

  return (
    <DECommonTable
      {...rest}
      tableKey={tableKey}
      columns={columns}
      sortableFields={sortableFields}
      filterIdentifier="CNU_TABLE"
      apiGetListAction={api.getUniversitiesList}
      apiBulkEditTags={api.universitiesBulkEditTags}
      apiBulkEditDistributors={api.universitiesBulkEditDistributors}
      companyTypeSlug="education-cu"
      searchPlaceholder="Search universities by name"
      FilterSet={UniversitiesFilterset}
      ExportModal={UniversityExportModal}
    />
  )
}

export function UniversitiesTable(
  props: UniversitiesTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      savedPresetsIdentifier="CNU_TABLE"
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Opportunity Attributes',
        'Campaigns and Deals',
        'Classification and Enrollment',
        'Dining Services',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <UniversitiesTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
