import styled from 'styled-components/macro'

export function FBPage({
  children,
  noContainer,
  className,
  headerChildren,
}: {
  children: React.ReactNode
  headerChildren?: React.ReactNode
  noContainer?: boolean
  className?: string
}) {
  return (
    <PageDiv className={className}>
      {headerChildren}
      <InnerPageDiv>
        {!noContainer ? <Container>{children}</Container> : children}
      </InnerPageDiv>
    </PageDiv>
  )
}

const PageDiv = styled.div`
  height: calc(100vh - var(--footer-height) - var(--header-height));
  display: flex;
  flex-direction: column;
`

const InnerPageDiv = styled.div`
  padding: var(--page-vertical-padding) var(--page-horizontal-padding);
  background-color: #f5f5f5;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  padding: var(--page-container-vertical-padding)
    var(--page-container-horizontal-padding);
  background-color: #fff;
  border-radius: 8px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  overflow: hidden;
`
