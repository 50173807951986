import { useState } from 'react'
import { BsStar, BsStarFill } from 'react-icons/bs'
import apiService from '../services/api'

export function ContactStarToggle(props: {
  contactId: number
  contactName: string
  initialStarred: boolean
}) {
  const api = apiService()
  const [starred, setStarred] = useState(props.initialStarred)

  async function handleStar() {
    const previous = starred
    try {
      setStarred(!starred)
      const res = await api.contactStarToggle(props.contactId)
      setStarred(res)
    } catch (e) {
      setStarred(previous)
    }
  }

  return (
    <div className={'flex flex-row items-center gap-2'}>
      <div className={'cursor-pointer'} onClick={handleStar}>
        {starred ? (
          <BsStarFill color={'#FFD582'} />
        ) : (
          <BsStar color={'#D0D3DD'} />
        )}
      </div>
      {props.contactName}
    </div>
  )
}
