import { cn } from 'components/UI/cn'
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

export type FbButtonVariants =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'danger-outline'

export interface IFbButtonProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'ref'
  > {
  loading?: boolean
  variant?: FbButtonVariants
  size?: 'sm' | 'md' | 'lg'
}

const FbButton = React.forwardRef((props: IFbButtonProps, ref: any) => {
  const { className, loading, variant, disabled, ...rest } = props
  const size = props.size ?? 'md'

  return (
    <button
      ref={ref}
      className={cn(
        'flex-shrink-0 min-w-0 text-base rounded-lg font-normal min-h-10 px-3 bg-[#2C6B4E] text-white flex items-center text-center gap-2 justify-center hover:bg-[#1E734C] hover:shadow-sm focus:outline-[#1E734C] disabled:border-transparent',
        {
          'bg-white text-black hover:text-white hover:bg-transparent border-gray-200 border-solid border-[1px]':
            variant === 'secondary',
          'bg-[#FDB81E] text-black hover:bg-[#FDB81E]': variant === 'warning',
          'bg-red-600 text-white hover:bg-red-700': variant === 'danger',
          'bg-transparent border-solid border-1 border-red-400 text-red-400 hover:text-red-400':
            variant === 'danger-outline',
          'bg-gray-200 text-gray-400 hover:text-gray-400 hover:bg-gray-200 hover:shadow-none':
            disabled,
          'min-h-14': size === 'lg',
          'min-h-10': size === 'md',
          'min-h-8': size === 'sm',
        },
        className
      )}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <div className="flex items-center w-full justify-center">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <>{props.children}</>
      )}
    </button>
  )
})
FbButton.displayName = 'FbButton'

export default FbButton

export const IconMiniButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
