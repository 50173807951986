import to from 'await-to-js'
import { TextInput } from 'components/FormUtils/TextInput'
import {
  addDays,
  endOfQuarter,
  endOfYear,
  startOfQuarter,
  startOfYear,
  isBefore,
  endOfToday,
} from 'date-fns'
import {
  ShoppingBag,
  Clock,
  Calendar,
  CalendarRange,
  CalendarDays,
} from 'lucide-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FbButton from '../../components/FbUI/FbButton'
import { DateRangePicker } from '../../components/FormUtils/DateRangePicker'
import { RadioButtonSelect } from '../../components/FormUtils/RadioButtonSelect'
import { Modal } from '../../components/UI/Modal/Modal'
import apiService from '../../services/api'
import Select from 'components/FormUtils/Select'
import { toast } from 'react-toastify'
import { queryClient } from '../../services/queryClient'
import { zodResolver } from '@hookform/resolvers/zod'

interface PeriodOption {
  label: string
  value: string
  dates: {
    from: Date
    to: Date
  }
  isDisabled: boolean
}

interface PeriodOptions {
  [key: string]: {
    quarters: PeriodOption[]
    halves: PeriodOption[]
  }
}

interface CreateRebateOfferModalProps {
  campaignId?: number
  onSuccess?: () => void
}

const createRebateOfferFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  type: z.string().min(1),
  basis: z.string().min(1),
  min: z.number().min(0.01, 'Minimum spend/units must be greater than 0'),
  max: z.number().min(0, 'Maximum must be greater than or equal to 0'),
  amount_off: z.number().min(0.01, 'Amount off must be greater than 0'),
  discount_type: z.string().min(1),
  period_type: z.string(),
  period_value: z.string(),
  period_year: z.string(),
  range: z.object({
    from: z.date(),
    to: z.date(),
  }),
  product_ids: z
    .array(z.union([z.string(), z.number()]))
    .min(1, 'At least one product must be selected'),
})

type CreateRebateOfferForm = z.infer<typeof createRebateOfferFormSchema>

const currentYear = new Date().getFullYear()
const today = endOfToday()

const generatePeriodOptions = (year: number) => {
  const quarters: PeriodOption[] = [
    {
      label: `Q1 ${year}`,
      value: `1-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 0)),
        to: endOfQuarter(new Date(year, 0)),
      },
      isDisabled: false,
    },
    {
      label: `Q2 ${year}`,
      value: `2-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 3)),
        to: endOfQuarter(new Date(year, 3)),
      },
      isDisabled: false,
    },
    {
      label: `Q3 ${year}`,
      value: `3-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 6)),
        to: endOfQuarter(new Date(year, 6)),
      },
      isDisabled: false,
    },
    {
      label: `Q4 ${year}`,
      value: `4-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 9)),
        to: endOfQuarter(new Date(year, 9)),
      },
      isDisabled: false,
    },
  ].map((quarter) => ({
    ...quarter,
    isDisabled: isBefore(quarter.dates.to, today),
  }))

  const halves: PeriodOption[] = [
    {
      label: `H1 ${year}`,
      value: `1-${year}`,
      dates: {
        from: startOfYear(new Date(year, 0)),
        to: endOfQuarter(new Date(year, 5)),
      },
      isDisabled: false,
    },
    {
      label: `H2 ${year}`,
      value: `2-${year}`,
      dates: {
        from: startOfQuarter(new Date(year, 6)),
        to: endOfYear(new Date(year, 0)),
      },
      isDisabled: false,
    },
  ].map((half) => ({
    ...half,
    isDisabled: isBefore(half.dates.to, today),
  }))

  return { quarters, halves }
}

export function CreateRebateOfferModal({
  campaignId,
  onSuccess,
}: CreateRebateOfferModalProps) {
  const [open, setOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const api = apiService()
  const { data: products, isLoading: isLoadingProducts } = api.useProducts()

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    reset,
    watch,
    setValue,
  } = useForm<CreateRebateOfferForm>({
    resolver: zodResolver(createRebateOfferFormSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      type: 'first-time-offer',
      basis: 'volume',
      period_type: 'custom',
      period_value: '',
      period_year: currentYear.toString(),
      range: {
        from: new Date(),
        to: addDays(new Date(), 20),
      },
      min: 0,
      max: 0,
      amount_off: 0,
      discount_type: 'percentage_off',
      product_ids: [],
    },
  })

  const periodType = watch('period_type')
  const periodValue = watch('period_value')
  const periodYear = watch('period_year')

  const periodOptions = useMemo<PeriodOptions>(
    () => ({
      [currentYear.toString()]: generatePeriodOptions(currentYear),
      [(currentYear + 1).toString()]: generatePeriodOptions(currentYear + 1),
    }),
    []
  )

  useEffect(() => {
    if (periodType !== 'custom' && periodValue && periodYear) {
      const options =
        periodType === 'quarter'
          ? periodOptions[periodYear]?.quarters
          : periodOptions[periodYear]?.halves
      const selectedPeriod = options?.find((opt) => opt.value === periodValue)
      if (selectedPeriod && !selectedPeriod.isDisabled) {
        setValue('range', {
          from: selectedPeriod.dates.from,
          to: selectedPeriod.dates.to,
        })
      }
    }
  }, [periodType, periodValue, periodYear, setValue, periodOptions])

  useEffect(() => {
    if (periodType !== 'custom' && periodYear) {
      const options =
        periodType === 'quarter'
          ? periodOptions[periodYear]?.quarters
          : periodOptions[periodYear]?.halves

      if (!options) return

      const currentSelection = options.find((opt) => opt.value === periodValue)
      if (!currentSelection || currentSelection.isDisabled) {
        const firstAvailable = options.find((opt) => !opt.isDisabled)
        if (firstAvailable) {
          setValue('period_value', firstAvailable.value)
          setValue('range', {
            from: firstAvailable.dates.from,
            to: firstAvailable.dates.to,
          })
        }
      }
    }
  }, [periodYear, periodType, periodValue, periodOptions, setValue])

  useEffect(() => {
    // When basis changes, update discount_type
    const basis = watch('basis')
    setValue(
      'discount_type',
      basis === 'volume' ? 'dollars_off' : 'percentage_off'
    )
  }, [watch('basis'), setValue])

  const onSubmit = handleSubmit(async (values) => {
    setIsSubmitting(true)
    await to(
      api.createRebateOffer({
        name: values.name,
        valid_from: values.range.from,
        valid_to: values.range.to,
        campaign_id: campaignId,
        terms: JSON.stringify({
          type: values.type,
          basis: values.basis,
          min: values.min,
          max: values.max,
          amount_off: values.amount_off,
          discount_type: values.discount_type,
          product_ids: values.product_ids,
          period_type: values.period_type,
          period_value: values.period_value,
          period_year: values.period_year,
        }),
      })
    )
    setIsSubmitting(false)
    toast.success('Rebate offer created successfully')
    queryClient.invalidateQueries({ queryKey: ['rebate-offers', campaignId] })
    reset()
    setOpen(false)
    onSuccess?.()
  })

  const basis = watch('basis')
  const min = watch('min')
  const max = watch('max')
  const amountOff = watch('amount_off')
  const selectedProductIds = watch('product_ids')

  const selectedProducts =
    products?.filter((p) => selectedProductIds.includes(p.id.toString())) || []
  const productOptions =
    products?.map((p) => ({
      label: p.name,
      value: p.id.toString(),
    })) || []

  const previewText =
    basis === 'volume'
      ? `Get at least ${min} units${max ? ` and up to ${max} units` : ''} of ${selectedProducts
          .map((p) => p.name)
          .join(', ')} and get $${amountOff} back per unit`
      : `For every $${min} spent${max ? ` (up to $${max})` : ''} on ${selectedProducts
          .map((p) => p.name)
          .join(', ')}, get ${amountOff}% back on the order`

  return (
    <Fragment>
      <FbButton onClick={() => setOpen(true)}>Create Rebate Offer</FbButton>
      <Modal
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            reset()
            setOpen(open)
          }
        }}
        loading={isSubmitting || isLoadingProducts}
        blockAccept={!isValid}
        title="Create Rebate Offer"
        description="Create a new rebate offer"
        onAccept={onSubmit}
        acceptButtonText={`Create Rebate ${isValid ? '(Valid)' : '(Invalid)'}`}
      >
        <TextInput
          control={control}
          name="name"
          label="Name of the offer"
          helperText={errors.name?.message}
        />

        <Select
          control={control}
          name="product_ids"
          label="Select Products"
          options={productOptions}
          isMulti
          isLoading={isLoadingProducts}
        />

        <RadioButtonSelect
          control={control}
          name="type"
          label="Rebate Type"
          options={[
            {
              label: 'First Time Offer',
              key: 'first-time-offer',
              icon: ShoppingBag,
            },
            {
              label: 'Time Based Offer',
              key: 'time-offer',
              icon: Clock,
            },
          ]}
        />

        <div className="space-y-4">
          {/* Period Selection Row */}
          <div>
            <RadioButtonSelect
              control={control}
              name="period_type"
              label="Period Type"
              options={[
                {
                  label: 'Quarter',
                  key: 'quarter',
                  icon: Calendar,
                },
                {
                  label: 'Half',
                  key: 'half',
                  icon: CalendarRange,
                },
                {
                  label: 'Custom Range',
                  key: 'custom',
                  icon: CalendarDays,
                },
              ]}
            />
          </div>

          {periodType !== 'custom' && (
            <div className="flex gap-4">
              <Select
                control={control}
                name="period_year"
                label="Select Year"
                options={[
                  {
                    label: currentYear.toString(),
                    value: currentYear.toString(),
                  },
                  {
                    label: (currentYear + 1).toString(),
                    value: (currentYear + 1).toString(),
                  },
                ]}
              />
              <Select
                control={control}
                name="period_value"
                label={`Select ${periodType === 'quarter' ? 'Quarter' : 'Half'}`}
                options={
                  periodType === 'quarter'
                    ? periodOptions[periodYear]?.quarters
                    : periodOptions[periodYear]?.halves
                }
              />
            </div>
          )}

          {periodType === 'custom' && (
            <DateRangePicker label="Period" control={control} name="range" />
          )}

          {/* Minimum and Maximum Row */}
          <div className="flex gap-4">
            <div className="flex-1">
              <TextInput
                type="number"
                control={control}
                name="min"
                label={basis === 'volume' ? 'Minimum Units' : 'Minimum Spend'}
                prefix={basis === 'volume' ? undefined : '$'}
                helperText={errors.min?.message}
              />
            </div>
            <div className="flex-1">
              <TextInput
                type="number"
                control={control}
                name="max"
                label={basis === 'volume' ? 'Maximum Units' : 'Maximum Spend'}
                prefix={basis === 'volume' ? undefined : '$'}
                helperText={errors.max?.message}
              />
            </div>
          </div>

          {/* Amount Off Row */}
          <div className="flex-1">
            <TextInput
              type="number"
              control={control}
              name="amount_off"
              label={
                basis === 'volume'
                  ? 'Amount Off per Unit'
                  : 'Percentage Off Order'
              }
              prefix={basis === 'volume' ? '$' : undefined}
              suffix={basis === 'volume' ? undefined : '%'}
              helperText={errors.amount_off?.message}
            />
          </div>
        </div>

        <div className="mt-4 p-4 bg-gray-50 rounded-md">
          <h3 className="text-lg font-medium mb-2">Offer Preview</h3>
          <p>{previewText}</p>
        </div>
      </Modal>
    </Fragment>
  )
}
