import { Hospital } from 'models/hospital'
import { DataTypeColumn } from '../Common/commonColumnHelpers'

export const HOSPITAL_COLUMNS = {
  hospital_name: {
    header: 'Hospital Name',
    group: 'Opportunity Attributes',
  },
  street_addr: {
    header: 'Address',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_full_address',
  },
  city: {
    header: 'City',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_city',
  },
  state: {
    header: 'State',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_state',
  },
  zip_code: {
    header: 'ZIP code',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_zipcode',
  },
  google_place_url: {
    header: 'Place URL',
    group: 'Opportunity Attributes',
    tooltipName: 'google_place_url',
  },
  meals_per_day: {
    header: 'Est. Meals Per Day',
    group: 'Dining Services',
    tooltipName: 'meals_per_day',
  },
  total_hospital_days: {
    header: 'Hospital Days',
    group: 'Hospital Insights',
    tooltipName: 'total_hospital_days',
  },
  total_hospital_beds: {
    header: 'Hospital Beds',
    group: 'Hospital Insights',
    tooltipName: 'total_hospital_beds',
  },
  cafeteria: {
    header: 'Cafeteria Expenditures',
    group: 'Dining Services',
    tooltipName: 'cafeteria',
  },
  dietary: {
    header: 'Patient Meal Expenditures',
    group: 'Dining Services',
    tooltipName: 'dietary',
  },
  dietary_under_contract: {
    header: 'Contracted Patient Meal Expenditures',
    group: 'Dining Services',
    tooltipName: 'dietary_under_contract',
  },
  domain: {
    header: 'Domain',
    group: 'Opportunity Attributes',
    tooltipName: 'opps_domain',
  },
  website_url: {
    header: 'Website',
    group: 'Opportunity Attributes',
    tooltipName: 'website_url',
  },
} satisfies Partial<Record<keyof Hospital, DataTypeColumn>>
