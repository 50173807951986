import { TextPill } from 'components/FbUI/TextPill'
import getRebateStatusColor from 'utils/getRebateStatusColor'
import { RebateClaimStatus } from './tradespend_api'

const STATUS_LABELS: Record<RebateClaimStatus, string> = {
  CLAIM_SUBMITTED: 'Claim Submitted',
  CLAIM_VALIDATED: 'Claim Validated',
  PENDING_MANUFACTURER_PAYMENT: 'Pending Manufacturer Payment',
  OPERATOR_PAYMENT_SUBMITTED: 'Operator Payment Submitted',
  PAID: 'Paid',
  CLAIM_REJECTED: 'Claim Rejected',
}

export function RebateStatusPill({ status }: { status: RebateClaimStatus }) {
  const colors = getRebateStatusColor(status)
  const label = STATUS_LABELS[status] || status

  return (
    <TextPill
      style={{
        marginBottom: 2,
      }}
      textColor={colors[1]}
      backgroundColor={colors[0]}
    >
      {label}
    </TextPill>
  )
}
