import { useQuery } from '@tanstack/react-query'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import { ContactResponse } from 'models/contacts'
import React, { useEffect, useMemo, useState } from 'react'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { TableSearch } from '../../Filters/TableSearch'
import * as S from '../CommonTable.styles'
import { useEmailsTableColumns } from './useEmailsTableColumns'
import EmailsFilterset from './EmailsFilterset'

type SortableFields = (keyof ContactResponse | string)[]

export type ContactsTableRef = {
  refetch: () => void
}

const sortableFields: SortableFields = [
  'outbound',
  'subject',
  'snippet',
  'date',
  'related_contacts',
]

const PAGE_SIZE = 100

interface EmailsTableProps {
  setTotalRowsCount?: (count: number) => void
  myContactsOnly?: boolean
  handleContactUpdated?: () => void
  disableUrlPagination?: boolean
  forChainProxyId?: number
  disableExportButton?: boolean
  tableKey: string
  filterIdentifierModifier?: string
  baseFilters?: Record<string, any>
}

function EmailsTableComponent(props: EmailsTableProps) {
  const api = apiService()
  const IDENTIFIER = 'EMAILS_TABLE' + (props.filterIdentifierModifier ?? '')
  const emailsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [pagination, setPagination] = props.disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)

  const filtersAsParams = {
    ...useFilterParams(emailsFilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(() => {
    const defaultParams = {
      ...filtersAsParams,
      ...sortParams,
    }
    if (props.forChainProxyId) {
      return {
        ...defaultParams,
        chain_proxy_id: props.forChainProxyId,
      }
    }
    return defaultParams
  }, [filtersAsParams, sortParams])

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      return await api.emailIntegration.getEmailMessageList(
        { ...filterAndSortParams, page: pagination.pageIndex + 1 },
        signal
      )
    },
  })
  // END DATA FETCHING

  const columns = useEmailsTableColumns()

  useEffect(() => {
    clearSelectedRows()
    if (data?.count) {
      props.setTotalRowsCount?.(data.count)
      setTotalRowsInBackend(data.count)
    }
  }, [data])

  return (
    <DataTableContainer>
      <DataTable
        key={props.tableKey}
        tableHeader={
          <S.SearchContainer>
            <S.SearchContainerFilters>
              <S.TableSearchContainer>
                <TableSearch
                  filterStore={emailsFilterStore}
                  searchPlaceholder="Search by contact name or email"
                />
              </S.TableSearchContainer>
              <S.TableButtonsContainer>
                <EmailsFilterset
                  filterIdentifier={IDENTIFIER as FilterIdentifier}
                />
                <ColumnSelectorRecipient tableKey={props.tableKey} />
              </S.TableButtonsContainer>
            </S.SearchContainerFilters>
            <FilterChips
              identifier={'EMAILS_TABLE'}
              store={emailsFilterStore}
              clearAllButton
              showActive
            />
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        virtualizeRows={false}
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText={'Select Contacts'}
      />
    </DataTableContainer>
  )
}

EmailsTableComponent.displayName = 'EmailsTable'

export function EmailsTable(
  props: EmailsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={['Email']}
    >
      <DataTableProvider tableKey={tableKey}>
        <EmailsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
