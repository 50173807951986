import { useEffect, useRef, useState } from 'react'

interface OrderableListProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.JSX.Element[]
  doNotpersistOrderInternally?: boolean
  onOrderChangeKey?: (orderedChildrenKeys: string[]) => void
  onDragStartKey?: (key: string) => void
  onDragEndKey?: () => void
}

export function OrderableListNew({
  children,
  doNotpersistOrderInternally,
  onOrderChangeKey,
  onDragStartKey,
  onDragEndKey,
  ...props
}: OrderableListProps) {
  const [orderedChildren, setOrderedChildren] = useState(children)
  // The index for the thing that shows where the dragged item will be placed
  const [destinationIndex, setDestinationIndex] = useState<number | null>(null)
  const currentDraggedItemIndex = useRef<number | null>(null)

  useEffect(() => {
    setOrderedChildren(children)
  }, [children])

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    currentDraggedItemIndex.current = index
    setDestinationIndex(index)
    onDragStartKey?.(orderedChildren[index].key! as string)
  }

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault()
    const element = e.target as HTMLElement
    // Get is below the middle of the element
    const mouseY = e.clientY
    const rect = element.getBoundingClientRect()
    const isBelowMiddle = mouseY > rect.y + rect.height / 2
    setDestinationIndex(isBelowMiddle ? index + 1 : index)
  }

  const handleDragEnd = () => {
    const getNewOrderedChildren = () => {
      if (currentDraggedItemIndex.current === null || destinationIndex === null)
        return orderedChildren
      const newOrderedChildren = [...orderedChildren]
      // Get the element that is being dragged
      const element = newOrderedChildren[currentDraggedItemIndex.current]
      // Add the element to the new index
      newOrderedChildren.splice(destinationIndex, 0, element)
      // Remove the element from the old index
      newOrderedChildren.splice(
        currentDraggedItemIndex.current! +
          (destinationIndex < currentDraggedItemIndex.current! ? 1 : 0),
        1
      )
      return newOrderedChildren
    }
    const newOrderedChildren = getNewOrderedChildren()
    if (!doNotpersistOrderInternally) {
      setOrderedChildren(newOrderedChildren)
    }
    currentDraggedItemIndex.current = null
    onOrderChangeKey?.(newOrderedChildren.map((child) => child.key! as string))
    onDragEndKey?.()
    setDestinationIndex(null)
  }

  return (
    <div {...props}>
      <div className="max-h-0">
        {orderedChildren?.map((child, index) => (
          <div key={child.key}>
            {destinationIndex === index && <DestinationNeedle />}
            <div
              key={child.key}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnd={handleDragEnd}
            >
              {child}
            </div>
            {destinationIndex === orderedChildren.length &&
              index === orderedChildren.length - 1 && <DestinationNeedle />}
          </div>
        ))}
      </div>
    </div>
  )
}

function DestinationNeedle() {
  return (
    <div key="destination" className="relative w-full pointer-events-none">
      <div className="bg-[#414942] rounded-full w-4 h-4 pointer-events-none absolute left-[-14px] top-1/2 transform -translate-y-1/2"></div>
      <div className="bg-[#414942] h-[1px] w-full"></div>
    </div>
  )
}
