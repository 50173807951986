import { FBPage } from 'components/FbUI/Page/FBPage'
import { useNavigate, useParams } from 'react-router-dom'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from '../../../components/FbUI/Page/styles'
import { Tabs } from '../../../components/FbUI/Tabs'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import { DistributorTable } from './DistributorTable'
import { MiscSettingsTable } from './MiscSettingsTable'
import { ProductTable } from './ProductTable'
import { SalesStageTable } from './SalesStageTable'
import { SavedFiltersTable } from './SavedFiltersTable'
import { TagTable } from './TagTable'
import { UserTable } from './UserTable'
import { useMemo } from 'react'
import { featureFlagService } from 'utils/featureFlagService'
import { EmailIntegrationTable } from './EmailIntegrationTable'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 95px)'

export function Settings() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.type ?? 'users'
  const store = useNavbarStore()
  const { enableEmailIntegration } = featureFlagService()

  const tabs = useMemo(
    () =>
      [
        { key: 'users', title: 'Users' },
        { key: 'products', title: 'Products' },
        { key: 'sales-stages', title: 'Sales Stages' },
        { key: 'filters', title: 'Filters' },
        { key: 'product-tags', title: 'Tags' },
        { key: 'distributors', title: 'Distributors' },
        { key: 'contact-tags', title: 'Contact Labels' },
        enableEmailIntegration && {
          key: 'email-integration',
          title: 'Email Integration',
        },
        { key: 'misc', title: 'Misc. Settings' },
      ].filter(Boolean) as Array<{ key: string; title: string }>,
    [enableEmailIntegration]
  )

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <FBPageTitle>Settings</FBPageTitle>
        </FBPageTitleRow>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/settings/${value}/`
            navigate(url)
            store.setLastSettingsVisited(url)
          }}
          tabStyle={{ width: '125px' }}
        />
      </FBPageInnerTabsContainer>

      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'users'}
      >
        <UserTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'products'}
      >
        <ProductTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'sales-stages'}
      >
        <SalesStageTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'filters'}
      >
        <SavedFiltersTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'distributors'}
      >
        <DistributorTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'product-tags'}
      >
        <TagTable tagParent="products" />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'contact-tags'}
      >
        <TagTable tagParent="contacts" />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'email-integration'}
      >
        <EmailIntegrationTable />
      </FBPageTableContainer>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'misc'}
      >
        <MiscSettingsTable />
      </FBPageTableContainer>
    </FBPage>
  )
}
