import { useNavigate, useParams } from 'react-router-dom'
import {
  FBPageHeader,
  FBPageInnerTabsContainer,
  FBPageTableContainer,
  FBPageTitle,
  FBPageTitleRow,
} from 'components/FbUI/Page/styles'
import { Tabs } from '../../../components/FbUI/Tabs'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import FbButton from '../../../components/FbUI/FbButton'
import apiService from '../../../services/api'
import { FBPage } from 'components/FbUI/Page/FBPage'
import { RebateOfferDetailsTab } from 'features/tradespend/RebateOfferDetailsTab'
import { RebateOfferEnrollmentsTab } from 'features/tradespend/RebateOfferEnrollmentsTab'

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 95px)'

const defaultEnrollmentsColumnVisibility = {
  tradespend_user_name: true,
  company_name: true,
  created: true,
}

export function RebateOfferDetails() {
  const navigate = useNavigate()
  const params = useParams()
  const selectedTab = params?.tab ?? 'details'
  const store = useNavbarStore()
  const api = apiService()

  const { data: offer } = api.useGetRebateOffer(Number(params.offerId))

  const tabs = [
    { key: 'details', title: 'Details' },
    { key: 'enrollments', title: 'Enrollments' },
  ]

  return (
    <FBPage>
      <FBPageHeader>
        <FBPageTitleRow>
          <div className="flex items-center gap-3">
            <FbButton
              onClick={() => {
                const url = '/rebates/offers'
                navigate(url)
                store.setLastRebateVisited(url)
              }}
            >
              Back to Rebates
            </FbButton>
            <FBPageTitle>Offer: {offer?.name}</FBPageTitle>
          </div>
        </FBPageTitleRow>
      </FBPageHeader>

      <FBPageInnerTabsContainer>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={(value) => {
            const url = `/rebates/offer/${params.offerId}/${value}/`
            navigate(url)
            store.setLastRebateVisited(url)
          }}
          tabStyle={{ width: '125px' }}
        />
      </FBPageInnerTabsContainer>

      {selectedTab === 'details' && <RebateOfferDetailsTab offer={offer} />}

      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={selectedTab !== 'enrollments'}
      >
        <RebateOfferEnrollmentsTab
          offerId={Number(params.offerId)}
          defaultColumnVisibility={defaultEnrollmentsColumnVisibility}
        />
      </FBPageTableContainer>
    </FBPage>
  )
}
