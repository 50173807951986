interface RebateStatusColors {
  [key: string]: string[]
}

export default function getRebateStatusColor(status: string) {
  const colors: RebateStatusColors = {
    CLAIM_SUBMITTED: ['#FEF3C7', '#D97706'], // Amber/Yellow for submitted
    CLAIM_VALIDATED: ['#D1FAE5', '#059669'], // Green for validated
    PENDING_MANUFACTURER_PAYMENT: ['#E0E7FF', '#4F46E5'], // Indigo for pending manufacturer
    OPERATOR_PAYMENT_SUBMITTED: ['#DBEAFE', '#2563EB'], // Blue for operator payment submitted
    PAID: ['#D1FAE5', '#059669'], // Green for paid
    CLAIM_REJECTED: ['#FEE2E2', '#DC2626'], // Red for rejected
  }

  return colors[status] || ['#F3F4F6', '#6B7280'] // Default gray
}
