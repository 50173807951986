import { cn } from 'components/UI/cn'
import { IoIosClose } from 'react-icons/io'

export function FilterCloseButton(props: {
  isActive: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center">
      <button
        type={'button'}
        className={cn(
          'flex items-center justify-center rounded cursor-default hover:bg-gray-200 transition-colors mr-1',
          {
            hidden: !props.isActive,
          }
        )}
        onClick={(e) => {
          e.stopPropagation()
          props.onClick?.(e)
        }}
      >
        <IoIosClose
          style={{ width: '32px', height: 'auto' }}
          className={'text-red-700'}
        />
      </button>
    </div>
  )
}
