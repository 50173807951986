import { create, StoreApi, UseBoundStore } from 'zustand'
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware'
import { FilterIdentifier } from 'models/saved_view'
import { useLocation } from 'react-router-dom'

export type FilterCriteria = 'one' | 'all' | 'neither'
export type ValueFilter = {
  value: string
  label: string
}
export type ValueFilterState = Record<string, ValueFilter[]>
export type SearchFilter = {
  value: string
  label: string
  elastic?: boolean
}
export type SearchFilterState = Record<string, SearchFilter>
export type RangeFilter = {
  min?: number
  max?: number
}
export type ZipCodeFilterState = [string, number]
export type RangeFilterState = Record<string, RangeFilter>
export type FilterStore = UseBoundStore<StoreApi<FilterState>>

export const withHistoryDOMEvents = (store: any) => {
  const storageEventCallback = () => {
    store.persist.rehydrate()
  }

  window.addEventListener('popstate', storageEventCallback)

  return () => {
    window.removeEventListener('popstate', storageEventCallback)
  }
}

const getUrlSearch = () => {
  return window.location.search.slice(1)
}

const persistentStorage: StateStorage = {
  getItem: (key): string | null => {
    const searchParams = new URLSearchParams(getUrlSearch())

    const urlValue = searchParams.get(key)
    const localStorageValue = localStorage.getItem(key)

    const jsonUrlValue = JSON.parse(urlValue as string)
    const jsonLocalStorageValue = JSON.parse(localStorageValue as string)

    if (getUrlSearch() && jsonUrlValue && jsonLocalStorageValue) {
      // Merge the URL and Local Storage
      for (const [k, v] of Object.entries(jsonLocalStorageValue)) {
        if (jsonUrlValue?.[k] === undefined) {
          jsonUrlValue[k] = v
        }
      }
    }

    // Put back zustand wrapper
    return JSON.stringify({ state: jsonUrlValue, version: 0 })
  },
  setItem: (key, newValue): void => {
    // Remove Zustand wrapper
    const filter = JSON.parse(newValue).state

    // Local Storage
    // Save entire state to local storage
    localStorage.setItem(key, JSON.stringify(filter))

    // Remove empty, pending and titles from the URL
    for (const [filterKey, filterValue] of Object.entries(filter)) {
      const isTitles = filterKey == 'filterTitles'
      const isPending = filterKey.includes('pending')
      const isZip = Array.isArray(filterValue) && !filterValue[0]
      const isEmpty =
        !Array.isArray(filterValue) &&
        Object.keys(filterValue ?? {}).length === 0
      if (isTitles || isPending || isZip || isEmpty) {
        delete filter?.[filterKey]
      }
    }

    const oldSearchParams = getUrlSearch()
    let searchParams = new URLSearchParams(getUrlSearch())
    searchParams.set(key, JSON.stringify(filter))

    // If the search params are the same, don't update the URL
    if (searchParams.toString() === oldSearchParams) {
      searchParams = new URLSearchParams(oldSearchParams)
    }

    // If there's only "criteria" in the filter, should be empty
    if (Object.keys(filter).length === 1 && filter.criteria) {
      searchParams.delete(key)
    }

    // Set the incoming filter to the object
    // if there are no filters, clear url search params
    const urlData = searchParams.toString()
    window.history.replaceState(
      null,
      '',
      urlData ? `?${urlData}` : window.location.pathname
    )
  },
  removeItem: (key): void => {
    const searchParams = new URLSearchParams(getUrlSearch())
    searchParams.delete(key)
    window.location.search = searchParams.toString()
  },
}

export interface FilterState {
  /*
            Pending filters are filters that have been selected but not yet applied.
            They should show up in the filter modal, but not yet be applied to the api call.
            */

  registerFilterTitle: (key: string, title: string) => void
  filterTitles: Record<string, string>

  // Zipcode
  zipCodeFilter: ZipCodeFilterState
  pendingZipCodeFilter: ZipCodeFilterState
  setPendingZipCodeFilter: (zipcode: string, mileRadius: number) => void
  clearPendingZipCodeFilter: () => void
  clearZipCodeFilter: () => void

  // Value filters
  valueFilters: ValueFilterState
  clearValueFilter: (key: string) => void
  pendingValueFilters: ValueFilterState
  setPendingValueFilters: (key: string, values: ValueFilter[]) => void
  clearPendingValueFilter: (key: string) => void

  // Range filters
  rangeFilters: RangeFilterState
  pendingRangeFilters: RangeFilterState
  setPendingRangeFilters: (key: string, min?: number, max?: number) => void
  clearPendingRangeFilter: (key: string) => void
  clearRangeFilter: (key: string) => void

  // Criteria
  criteria: FilterCriteria
  pendingCriteria: FilterCriteria
  setPendingCriteria: (criteria: FilterCriteria) => void

  applyFilters: () => void
  clearAllFilters: () => void
  clearAllPendingFilters: () => void

  search: SearchFilterState
  pendingSearch: SearchFilterState
  setSearch: (
    key: string,
    value: string,
    label: string,
    elastic?: boolean
  ) => void
  clearSearch: (key: string) => void
  clearPendingSearch: (key: string) => void

  loadSavedFilter: (filters: Record<any, any>) => void
  syncPendingFilters: () => void
}

class FilterStoreRepo {
  private repo: Record<string, FilterStore> = {}

  getStore(storeName: FilterIdentifier): FilterStore {
    if (!this.repo[storeName]) {
      this.repo[storeName] = filterStoreFactory(storeName)
    }

    return this.repo[storeName]
  }
}

export const filterStoreRepo = new FilterStoreRepo()

export function filterStoreFactory(storeName: FilterIdentifier) {
  const store = create(
    persist<FilterState>(
      (set) => ({
        search: {},
        pendingSearch: {},
        setSearch(key, value, label, elastic = false) {
          set((state) => {
            if (value === '') {
              return {
                search: {},
              }
            }
            return {
              search: {
                ...state.search,
                [key]: { value: value, label: label, elastic: elastic },
              },
            }
          })
        },
        clearSearch: (key: string) => {
          set((state) => {
            const search = { ...state.search }
            delete search[key]
            return { search: search }
          })
        },
        clearPendingSearch: (key: string) => {
          set((state) => {
            const search = { ...state.search }
            delete search[key]
            return { pendingSearch: search }
          })
        },

        filterTitles: {},

        zipCodeFilter: ['', 0],
        pendingZipCodeFilter: ['', 0],

        valueFilters: {},
        pendingValueFilters: {},

        rangeFilters: {},
        pendingRangeFilters: {},

        criteria: 'all',
        pendingCriteria: 'all',

        setPendingValueFilters: (key: string, values: ValueFilter[]) => {
          set((state) => {
            const pending = {
              ...state.pendingValueFilters,
              [key]: values,
            }
            if (!values.length) {
              delete pending[key]
            }
            return { pendingValueFilters: pending }
          })
        },
        clearPendingValueFilter: (key) => {
          set((state) => {
            const pending = { ...state.pendingValueFilters }
            delete pending[key]
            return { pendingValueFilters: pending }
          })
        },

        setPendingRangeFilters(key, min, max) {
          set((state) => {
            const pending = {
              ...state.pendingRangeFilters,
              [key]: { min, max },
            }
            return { pendingRangeFilters: pending }
          })
        },
        clearPendingRangeFilter(key) {
          set((state) => {
            const pending = { ...state.pendingRangeFilters }
            delete pending[key]
            return { pendingRangeFilters: pending }
          })
        },

        setPendingZipCodeFilter: (zipcode: string, mileRadius: number) => {
          set(() => {
            return { pendingZipCodeFilter: [zipcode, mileRadius] }
          })
        },
        clearPendingZipCodeFilter: () => {
          set(() => {
            return { pendingZipCodeFilter: ['', 0] }
          })
        },

        setPendingCriteria: (criteria: FilterCriteria) => {
          set(() => {
            return { pendingCriteria: criteria }
          })
        },

        clearAllPendingFilters: () => {
          set(() => {
            return {
              pendingValueFilters: {},
              pendingRangeFilters: {},
              pendingZipCodeFilter: ['', 0],
              pendingCriteria: 'all',
            }
          })
        },

        clearValueFilter: (key) => {
          set((state) => {
            const values = { ...state.valueFilters }
            delete values[key]
            return { valueFilters: values }
          })
        },
        clearZipCodeFilter: () => {
          set(() => {
            return { zipCodeFilter: ['', 0] }
          })
        },
        clearRangeFilter(key) {
          set((state) => {
            const ranges = { ...state.rangeFilters }
            delete ranges[key]
            return { rangeFilters: ranges }
          })
        },

        applyFilters: () => {
          set((state) => {
            return {
              valueFilters: state.pendingValueFilters,
              rangeFilters: state.pendingRangeFilters,
              zipCodeFilter: state.pendingZipCodeFilter,
              criteria: state.pendingCriteria,
              search: state.pendingSearch,
            }
          })
        },
        clearAllFilters: () => {
          set(() => {
            return {
              valueFilters: {},
              pendingValueFilters: {},
              rangeFilters: {},
              pendingRangeFilters: {},
              zipCodeFilter: ['', 0],
              pendingZipCodeFilter: ['', 0],
              criteria: 'all',
              pendingCriteria: 'all',
              search: {},
            }
          })
        },

        registerFilterTitle: (key: string, title: string) => {
          set((state) => {
            return { filterTitles: { ...state.filterTitles, [key]: title } }
          })
        },

        loadSavedFilter: (filters: Record<any, any>) => {
          set(() => {
            return {
              pendingValueFilters: filters.valueFilters,
              pendingRangeFilters: filters.rangeFilters,
              pendingZipCodeFilter: filters.zipCodeFilter,
              pendingCriteria: filters.criteria,
              pendingSearch: filters.search,
            }
          })
        },

        syncPendingFilters: () => {
          set((state) => {
            return {
              pendingValueFilters: state.valueFilters,
              pendingRangeFilters: state.rangeFilters,
              pendingZipCodeFilter: state.zipCodeFilter,
              pendingCriteria: state.criteria,
              pendingSearch: state.search,
            }
          })
        },
      }),
      {
        name: storeName,

        storage: createJSONStorage<FilterStore>(() => persistentStorage) as any,
      }
    )
  )

  withHistoryDOMEvents(store)

  return store
}

export function buildFilterParams(
  valueFilters: FilterState['valueFilters'],
  rangeFilters: FilterState['rangeFilters'],
  zipCodeFilter: FilterState['zipCodeFilter'],
  searchFilters: FilterState['search'],
  criteria: FilterState['criteria'],
  isCampaignPage?: boolean
): Record<string, any> {
  const params: Record<string, unknown> = {
    type: criteria,
  }

  for (const [key, values] of Object.entries(valueFilters)) {
    params[key] = values.map((v) => v.value)
  }

  for (const [key, range] of Object.entries(rangeFilters)) {
    if (range.min !== undefined) {
      params[`${key}_greater`] = range.min.toString()
    }
    if (range.max !== undefined) {
      params[`${key}_less`] = range.max.toString()
    }
  }

  if (zipCodeFilter[0]) {
    params.zipcode = zipCodeFilter[0]
    params.radius = zipCodeFilter[1]
  }

  const elasticQueryParts: string[] = []

  for (const [key, value] of Object.entries(searchFilters)) {
    if (value.value) {
      if (value.elastic) {
        elasticQueryParts.push(`${key}:${value.value}`)
      } else {
        params[key] = value.value
      }
    }
  }

  const elasticQuery = elasticQueryParts.join(';')

  if (elasticQuery) {
    params.search_query = elasticQuery
  }

  if (isCampaignPage) {
    params.campaign_page = true
  }

  return { ...params }
}

export function useFilterParams(store: FilterStore) {
  const filters = store()
  const { criteria, valueFilters, rangeFilters, zipCodeFilter, search } =
    filters
  const location = useLocation()
  const isCampaignPage = location.pathname.includes('/campaigns/')

  return buildFilterParams(
    valueFilters,
    rangeFilters,
    zipCodeFilter,
    search,
    criteria,
    isCampaignPage
  )
}

export const restaurantsFilterStore = filterStoreFactory('DOORS_TABLE')
export const activityV2FilterStore = filterStoreFactory('ACTIVITY_TABLE_V2')
export const dealsPipelineFilterStore = filterStoreFactory(
  'DEALS_PIPELINE_TABLE'
)
