import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import to from 'await-to-js'
import { ActionButtons } from 'components/Buttons/ActionButtons'
import { DataTable, DataTableProvider } from 'components/DataTable'
import dialogConfirm from 'components/dialogConfirm'
import { OverflownTextTooltip } from 'components/OverflownTextTooltip'
import { DateCell } from 'components/Tables/CommonTableCells/DateCell'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { getTableQueryKey } from 'utils/getTableQueryKey'
import { z } from 'zod'
import { dataTableSortingStoreRepo } from '../../../components/DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../../components/DataTable/UI'
import FbButton from '../../../components/FbUI/FbButton'
import { TextInput } from '../../../components/FormUtils/TextInput'
import { Modal } from '../../../components/UI/Modal/Modal'
import { handleFormError } from '../../../utils/handleFormError'
import { usePaginationURLParams } from '../../../utils/usePaginationURLParams'
import * as S from './styles'
import { EmailIntegration } from 'models/emailIntegration'
import Toggle from 'react-toggle'
import { FiRefreshCw } from 'react-icons/fi'

const defaultValuesTag = {
  email: '',
  is_shared: false,
}

const formSchema = z.object({
  email: z.string().optional(),
  is_shared: z.boolean(),
})

type FormSchema = z.infer<typeof formSchema>

const PAGE_SIZE = 25

const sortableFields = ['email', 'modified', 'created']

const tableKey = 'email-integration-table'
export function EmailIntegrationTableComponent() {
  const api = apiService()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedEmailID, setSelectedEmailID] = useState<number>()
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
  const { sorting } = dataTableSortingStoreRepo.getStore(tableKey)()

  const sortParams = useMemo(() => {
    const params: Record<string, string> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const success = urlParams.get('success')
    if (success) {
      setShowSuccessModal(true)
    }
  }, [])

  function openModal() {
    setShowModal(true)
  }

  function hideModal() {
    setShowModal(false)
    reset(defaultValuesTag)
    setSelectedEmailID(undefined)
  }

  const [pagination, setPagination] = usePaginationURLParams(
    PAGE_SIZE,
    `email-integration-table`
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey,
    filterParams: sortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      const [err, data] = await to(
        api.emailIntegration.getEmailIntegrationList(
          {
            ...sortParams,
            limit: PAGE_SIZE,
            page: pagination.pageIndex + 1,
          },
          signal
        )
      )

      if (err) {
        throw err
      }

      return data
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    setError,
    setValue,
    reset,
    watch,
  } = useForm<FormSchema>({
    defaultValues: defaultValuesTag,
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const isShared = watch('is_shared')

  const onSubmitCreate = handleSubmit(async (values) => {
    const [err, response] = await to(
      api.emailIntegration.getEmailIntegrationAuthUrl(
        values.email!,
        values.is_shared
      )
    )
    if (err) {
      handleFormError(err, setError)
      toast.error('Error creating email')
      return
    }

    if (response?.auth_url) {
      // Redirect to the auth url
      window.location.href = response.auth_url
    }

    toast.success('Email integration requested')
    await refetch()
    hideModal()
  })

  const onSubmitUpdate = handleSubmit(async (values) => {
    if (!selectedEmailID) return

    const [err] = await to(
      api.emailIntegration.updateEmailIntegration(selectedEmailID, {
        is_shared: values.is_shared,
      })
    )
    if (err) {
      handleFormError(err, setError)
      toast.error('Error updating email')
      return
    }

    toast.success('Email updated')
    await refetch()
    hideModal()
  })

  async function handleRowDelete(index: number) {
    const id = data?.results?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this email',
        'Delete Email'
      )
    ) {
      const [err] = await to(api.emailIntegration.deleteEmailIntegration(id))
      if (err) {
        handleFormError(err, setError)
        toast.error('Error deleting email')
        return
      }
      toast.success('Email deleted')
      await refetch()
    }
  }

  function handleRowEdit(index: number) {
    setShowModal(true)
    const email = data?.results?.[index]
    if (email) {
      setSelectedEmailID(email.id)
      reset({
        email: email.email,
        is_shared: email.is_shared,
      })
    }
  }

  async function syncEmailIntegration(id: number) {
    const [err] = await to(api.emailIntegration.syncEmailIntegration(id))
    if (err) {
      toast.error('Error syncing email')
      return
    }
    toast.info(
      'Started syncing emails for this account. You will be notified when it is done.'
    )
    await refetch()
  }

  const columnHelper = createColumnHelper<EmailIntegration>()

  const descriptionText = `Email Integration allows you to send emails to your contacts directly from the platform.`

  return (
    <DataTableContainer>
      <DataTable
        tableHeader={
          <S.SettingsTableHeader>
            <S.TableDescriptionText>
              <OverflownTextTooltip
                tooltipText={descriptionText}
                maxLines={4}
                placement="bottom"
              >
                {descriptionText}
              </OverflownTextTooltip>
            </S.TableDescriptionText>
            <FbButton onClick={() => openModal()}>Add new email</FbButton>
          </S.SettingsTableHeader>
        }
        tableKey={tableKey}
        data={data?.results ?? []}
        columns={[
          columnHelper.accessor('email', {
            header: 'Email',
            size: 200,
          }),
          columnHelper.accessor('is_shared', {
            header: 'Shared',
            size: 150,
            cell: ({ row }) => {
              return row.original.is_shared ? 'Yes' : 'No'
            },
          }),
          columnHelper.accessor('status', {
            header: 'Status',
            size: 100,
            cell: (info) => {
              const value = info.getValue()
              const valueMap = {
                synced: 'Synced',
                syncing: 'Syncing',
                not_synced: 'Not Synced',
              }
              return (
                <span
                  className={`${
                    value === 'synced'
                      ? 'text-green-500'
                      : value === 'syncing'
                        ? 'text-yellow-500'
                        : 'text-red-500'
                  }`}
                >
                  {valueMap[value as keyof typeof valueMap]}
                </span>
              )
            },
          }),
          columnHelper.accessor('modified', {
            header: 'Modified',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.accessor('created', {
            header: 'Created',
            size: 100,
            cell: DateCell,
          }),
          columnHelper.display({
            id: 'edit',
            header: 'Actions',
            meta: { rightAlign: true },
            cell: ({ row }) => {
              return (
                <div className="flex justify-end">
                  <FiRefreshCw
                    size={20}
                    color="#667085"
                    className="cursor-pointer mr-3 justify-end"
                    onClick={() => syncEmailIntegration(row.original.id)}
                  />
                  <div>
                    <ActionButtons
                      className="justify-end pr-0"
                      onDelete={() => handleRowDelete(row.index)}
                      onEdit={() => handleRowEdit(row.index)}
                    />
                  </div>
                </div>
              )
            },
            size: 100,
          }),
        ]}
        sortableFields={sortableFields}
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        loading={isFetching}
      />
      <Modal
        open={showModal}
        title={selectedEmailID ? 'Edit Email' : 'Create Email'}
        description="Add a new email to the platform."
        onOpenChange={(open) => !open && hideModal()}
        loading={isSubmitting}
        blockAccept={!isValid}
        onAccept={selectedEmailID ? onSubmitUpdate : onSubmitCreate}
        acceptButtonText={selectedEmailID ? 'Update' : 'Create'}
      >
        <TextInput
          name="email"
          type="text"
          control={control}
          label={'Email'}
          placeholder="Email"
          disabled={!!selectedEmailID}
        />
        {/* Toggle for shared/private */}
        <div className="flex gap-2 mt-2 w-full justify-center">
          <span className="vertical-align-top">Private</span>
          <Toggle
            defaultChecked={false}
            onChange={(e) => setValue('is_shared', e.target.checked)}
            checked={isShared}
          />
          <span className="vertical-align-top">Shared</span>
        </div>
      </Modal>
      <Modal
        open={showSuccessModal}
        title={'Success'}
        description="Email integrated successfully"
        onOpenChange={(open) => !open && setShowSuccessModal(false)}
        onAccept={() => setShowSuccessModal(false)}
        acceptButtonText={'Close'}
        hideCancelButton
      />
    </DataTableContainer>
  )
}

export function EmailIntegrationTable() {
  return (
    <ColumnsStoreProvider tableKey={tableKey}>
      <DataTableProvider tableKey={tableKey}>
        <EmailIntegrationTableComponent />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
