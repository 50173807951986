import { usePreferences } from 'context/preferences/PreferencesContext'
import { ContactCompany } from 'models/companies'
import { ContactCompanyType } from 'models/contact_companies'
import { DealsPipeline } from 'models/deals_pipeline'

type CompanyRequiredFields = {
  id: number
  company_type?: ContactCompanyType
  chain?: string
  university_id?: number
  k12district_id?: number
}

/**
 * Gets the url for the company based on the type
 *
 * @param company ContactCompany object
 * @returns url for the company
 */
export const getCompanyUrl = (
  company: CompanyRequiredFields | ContactCompany
): string => {
  const { preferences } = usePreferences()

  if (!company || !preferences?.company_type_prefix_map) return '-'

  const { company_type, chain, id, university_id, k12district_id } = company

  switch (company_type) {
    case ContactCompanyType.Chain:
      return `/chain/${preferences.company_type_prefix_map[company_type]}${chain}`
    case ContactCompanyType.University:
      return `/university/${preferences.company_type_prefix_map[company_type]}${university_id}`
    case ContactCompanyType.K12District:
      return `/k12/${preferences.company_type_prefix_map[company_type]}${k12district_id}`
    case ContactCompanyType.UserGenerated:
      return `/company/${preferences.company_type_prefix_map[company_type]}${id}`
    default:
      return `/company/${preferences.company_type_prefix_map[ContactCompanyType.UserGenerated]}${id}`
  }
}

/**
 * Gets the url for the company based on the type
 *
 * @param company DealsPipeline object
 * @returns url for the company
 */
export const getCompanyUrlFromDealPipeline = (
  dealPipelineRecord: DealsPipeline
): string => {
  const { preferences } = usePreferences()
  if (!dealPipelineRecord || !preferences?.company_type_prefix_map) return '-'
  const {
    company_type,
    chain_id,
    contact_company_id,
    university_id,
    k12district_id,
    nursinghome_id,
  } = dealPipelineRecord

  switch (company_type) {
    case ContactCompanyType.Chain:
      return `/chain/${preferences.company_type_prefix_map[company_type]}${chain_id}`
    case ContactCompanyType.University:
      return `/university/${preferences.company_type_prefix_map[company_type]}${university_id}`
    case ContactCompanyType.K12District:
      return `/k12/${preferences.company_type_prefix_map[company_type]}${k12district_id}`
    case ContactCompanyType.NursingHome:
      return `/nursinghome/${preferences.company_type_prefix_map[company_type]}${nursinghome_id}`
    case ContactCompanyType.UserGenerated:
      return `/company/${preferences.company_type_prefix_map[company_type]}${contact_company_id}`
    default:
      return `/company/${preferences.company_type_prefix_map[ContactCompanyType.UserGenerated]}${contact_company_id}`
  }
}
