import apiService from 'services/api'
import ExportModal, { ExportModalProps } from './ExportModal'
import { downloadFile } from 'utils/csvDownloader'
import { UNIVERSITY_COLUMNS } from 'components/DataExpansionComponents/Universities/universityColumnsInfo'
import { createExportColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { getCommonChainProxyExportColumns } from 'components/DataExpansionComponents/Common/commonExportColumns'

export default function UniversityExportModal(props: ExportModalProps) {
  const api = apiService()

  const exportUniversities = async ({
    filter,
    selectedColumns,
    exportType,
    ids,
    excludeIds,
    controller,
  }: {
    filter: Record<string, unknown>
    selectedColumns: string[]
    exportType: 'csv' | 'xlsx'
    controller?: AbortController
    ids?: number[] | undefined
    excludeIds?: number[] | undefined
  }) => {
    const res = await api.exportUniversities(
      filter,
      selectedColumns,
      exportType,
      ids,
      excludeIds,
      controller?.signal
    )
    downloadFile(
      res,
      `First Bite Export: Universities - ${new Date().toLocaleDateString()}.${exportType}`
    )
  }

  const universityColumnHelper = createExportColumnHelper({
    columns: UNIVERSITY_COLUMNS,
  })

  const columns = [
    {
      title: 'Opportunity Attributes',
      elements: [
        universityColumnHelper.retrieveColumn('institution_name'),
        universityColumnHelper.retrieveColumn('address'),
        universityColumnHelper.retrieveColumn('city'),
        universityColumnHelper.retrieveColumn('state_abbr'),
        universityColumnHelper.retrieveColumn('zipcode'),
        universityColumnHelper.retrieveColumn('domain'),
        universityColumnHelper.retrieveColumn(
          'carnegie_classification_2021_size_setting_size'
        ),
        universityColumnHelper.retrieveColumn(
          'carnegie_classification_2021_size_setting_year'
        ),
        universityColumnHelper.retrieveColumn(
          'carnegie_classification_2021_size_setting_student_composition'
        ),
        universityColumnHelper.retrieveColumn('total_enrollment'),
        universityColumnHelper.retrieveColumn('total_men_enrollment'),
        universityColumnHelper.retrieveColumn('total_women_enrollment'),
        universityColumnHelper.retrieveColumn('dormitory_capacity'),
        universityColumnHelper.retrieveColumn('google_place_url'),
      ],
    },
    {
      title: 'Dining Services',
      elements: [
        universityColumnHelper.retrieveColumn('meal_plan_provided'),
        universityColumnHelper.retrieveColumn('food_management_company'),
        universityColumnHelper.retrieveColumn('food_management_company_parent'),
        universityColumnHelper.retrieveColumn('estimated_meals_per_day'),
      ],
    },
    {
      title: 'Predicted Values and Deals',
      elements: getCommonChainProxyExportColumns(),
    },
  ]

  return (
    <ExportModal
      title="Select Columns for Universities Download"
      subTitle="Download up to 10,000 universities at a time, with any of the following columns. Any filters already applied will remain active."
      submitText="Export Universities"
      columns={columns}
      exportAPIAction={exportUniversities}
      {...props}
    />
  )
}
