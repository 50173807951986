import { LTVProcessingBanner } from 'components/Banners/LTVProcessingBanner'
import { HospitalsTable } from 'components/DataExpansionComponents/Hospitals/HospitalsTable/HospitalsTable'
import { K12Table } from 'components/DataExpansionComponents/K12/K12Table/K12Table'
import { UniversitiesTable } from 'components/DataExpansionComponents/Universities/UniversitiesTable/UniversitiesTable'
import { NursingHomesTable } from 'components/DataExpansionComponents/NursingHomes/NursingHomeTable/NursingHomesTable'
import { FBPage } from 'components/FbUI/Page/FBPage'
import { TabsWithDropdown } from 'components/FbUI/TabsWithDropdown'
import NotFoundCard from 'components/NotFoundCard/NotFoundCard'
import { CompaniesTable } from 'components/Tables/CompaniesTable/CompaniesTable'
import { DoorsTable } from 'components/Tables/DoorsTable/DoorsTable'
import { HelpTooltip } from 'components/Tooltip/HelpTooltip'
import useDocumentTitle from 'components/useDocumentTitle'
import {
  CHAINS_TABLE_KEY,
  COLLEGE_AND_UNIVERSITY_TABLE_KEY,
  DOORS_TABLE_KEY,
  getCompanyTableKey,
  HOSPITALS_TABLE_KEY,
  K12_TABLE_KEY,
  NURSING_HOMES_TABLE_KEY,
} from 'constants/tableQueryKeys'
import { CompanyTypeSlugWithFBData } from 'models/companies'
import { OpportunitySubCategorySlug } from 'models/opportunities'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { featureFlagService } from 'utils/featureFlagService'
import { FBPageTableContainer } from '../../../components/FbUI/Page/styles'
import LoadingScreen from '../../../components/Layout/LoadingScreen'
import { useNavbarStore } from '../../../components/Layout/navbar-store'
import { ChainsTable } from '../../../components/Tables/ChainsTable/ChainsTable'
import { ChainsSummary, PlacesSummary } from '../../../models/summaries'
import { StatsCard, StatsContainer } from '../../../styled/Stats'
import { formatInteger, formatUsd } from '../../../utils/formatting'
import OpportunitiesTabs from './OpportunitiesTabs'
import {
  OpportunitiesProvider,
  useOpportunitiesContext,
} from './opportunities-context'
import {
  OpportunityDropdownItemValue,
  useOpportunitiesTabs,
} from './useOpportunitiesTabs'

export interface IOpportunityStat {
  name: string
  value: number | string | undefined
  isLoading: boolean | undefined
  tooltip?: string
}

const TABLE_CONTAINER_HEIGHT = 'calc(var(--base-page-table-height) - 125px)'

export function Opportunities() {
  const navigate = useNavigate()
  const {
    enableCnUTable,
    enableK12Table,
    enableHospitals,
    enableNursingHomes,
  } = featureFlagService()
  const { doorsTabSelected, chainsTabSelected } = useOpportunitiesContext()
  const { setLastOppVisited } = useNavbarStore()
  const {
    mainTabs,
    categoryActive,
    subCategoryActive,
    restaurantsTabsSelected,
    universitiesTabSelected,
    hospitalsTabSelected,
    k12selected,
    nursingHomesTabSelected,
    isLoading,
  } = useOpportunitiesTabs()

  useDocumentTitle(
    `${subCategoryActive?.name} - ${categoryActive?.name} - First Bite`
  )

  const [unmatched, setUnmatched] = useState<boolean>(false)
  const [companiesCount, setCompaniesCount] = useState<number>()
  const companyStats: IOpportunityStat[] = [
    {
      isLoading: companiesCount === undefined,
      name: 'Total Companies',
      value: companiesCount,
    },
  ]

  const [placesSummary, setPlacesSummary] = useState<PlacesSummary>({
    isLoading: true,
    total_ltv: 0,
    chains_count: 0,
    places_count: 0,
  })

  const [chainsSummary, setChainsSummary] = useState<ChainsSummary>({
    isLoading: true,
    total_ltv: 0,
    count: 0,
    places_count: 0,
    avg_pounds_per_year: 0,
    avg_total_ltv: 0,
  })

  const placesStats: IOpportunityStat[] = [
    {
      name: 'Total LTV',
      value: formatUsd(placesSummary?.total_ltv),
      isLoading: placesSummary?.isLoading,
    },
    {
      name: 'Total Chains',
      value: placesSummary?.chains_count?.toLocaleString('en-US'),
      isLoading: placesSummary?.isLoading,
      tooltip:
        'The sum of unique chains for the doors matching the applied filters and/or search criteria',
    },
    {
      name: 'Total Doors',
      value: placesSummary?.places_count?.toLocaleString('en-US'),
      isLoading: placesSummary?.isLoading,
      tooltip:
        'The total number of doors that match the applied filters and/or search criteria.',
    },
  ]

  const chainStats: IOpportunityStat[] = [
    {
      name: 'Total LTV',
      value: formatUsd(chainsSummary?.total_ltv),
      isLoading: chainsSummary?.isLoading,
    },
    {
      name: 'Total Chains',
      value: formatInteger(chainsSummary?.count),
      isLoading: chainsSummary?.isLoading,
      tooltip:
        'The total number of chains that have at least 1 door that matches the applied filters and/or search criteria.',
    },
    {
      name: 'Total Doors',
      value: formatInteger(chainsSummary?.places_count),
      isLoading: chainsSummary?.isLoading,
      tooltip:
        'The sum of doors for all chains that match the applied filters and/or search criteria.',
    },
  ]

  const restStats = doorsTabSelected
    ? placesStats
    : chainsTabSelected
      ? chainStats
      : []

  const [universityCount, setUniversityCount] = useState<number>()
  const universityStats = useMemo(
    () => [
      {
        isLoading: universityCount === undefined,
        name: 'Total Universities',
        value: formatInteger(universityCount),
      },
    ],
    [universityCount]
  )

  const [k12Count, setK12Count] = useState<number>()
  const k12Stats = useMemo(
    () => [
      {
        isLoading: k12Count === undefined,
        name: 'Total K-12 Districts',
        value: formatInteger(k12Count),
      },
    ],
    [k12Count]
  )

  const [hospitalCount, setHospitalCount] = useState<number>()
  const hospitalStats = useMemo(
    () => [
      {
        isLoading: hospitalCount === undefined,
        name: 'Total Hospitals',
        value: formatInteger(hospitalCount),
      },
    ],
    [hospitalCount]
  )

  // Add nursing homes state and stats
  const [nursingHomeCount, setNursingHomeCount] = useState<number>()
  const nursingHomeStats = useMemo(
    () => [
      {
        isLoading: nursingHomeCount === undefined,
        name: 'Total Nursing Homes',
        value: formatInteger(nursingHomeCount),
      },
    ],
    [nursingHomeCount]
  )

  const renderStats = () => {
    let statsToRender: IOpportunityStat[] = []
    if (unmatched) {
      statsToRender = companyStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.RESTAURANTS_BARS
    ) {
      statsToRender = restStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.EDUCATION_CU
    ) {
      statsToRender = universityStats
    } else if (
      subCategoryActive?.slug === OpportunitySubCategorySlug.EDUCATION_K_12
    ) {
      statsToRender = k12Stats
    } else if (
      subCategoryActive?.slug ===
      OpportunitySubCategorySlug.HEALTHCARE_HOSPITALS
    ) {
      statsToRender = hospitalStats
    } else if (
      subCategoryActive?.slug ===
      OpportunitySubCategorySlug.HEALTHCARE_NURSING_HOMES
    ) {
      statsToRender = nursingHomeStats
    }

    return (
      <StatsContainer>
        {statsToRender.map((stat) => (
          <StatsCard key={stat.name}>
            <div className="flex justify-between w-full items-center">
              <h1> {stat.name} </h1>
              <HelpTooltip content={stat.tooltip} />
            </div>
            {!stat.isLoading ? (
              <span> {stat.value} </span>
            ) : (
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                Calculating...
              </span>
            )}
          </StatsCard>
        ))}
      </StatsContainer>
    )
  }

  const tables = useMemo(() => {
    if (unmatched) {
      return (
        <>
          {categoryActive && subCategoryActive && (
            <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
              <CompaniesTable
                key={getCompanyTableKey(subCategoryActive.slug)}
                setTotalRowsCount={setCompaniesCount}
                pageParamName={`${subCategoryActive.slug}-page`}
                subCategory={subCategoryActive}
                category={categoryActive}
                defaultColumnVisibility={{ city: false, country: false }}
                tableKey={`${subCategoryActive.slug}-company-table`}
              />
            </FBPageTableContainer>
          )}
        </>
      )
    }

    const tableBySlugType: Record<
      CompanyTypeSlugWithFBData,
      React.JSX.Element
    > = {
      'restaurants-bars': (
        <DoorChainTable
          setPlacesSummary={setPlacesSummary}
          setChainsSummary={setChainsSummary}
        />
      ),
      'education-cu': (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <UniversitiesTable
            defaultColumnVisibility={{
              total_men_enrollment: false,
              total_women_enrollment: false,
            }}
            tableKey={COLLEGE_AND_UNIVERSITY_TABLE_KEY}
            setTotalRowsCount={setUniversityCount}
          />
        </FBPageTableContainer>
      ),
      'education-k-12': (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <K12Table
            tableKey={K12_TABLE_KEY}
            defaultColumnVisibility={{
              k12district__address: false,
              k12district__county: false,
              k12district__teachers_count: false,
              k12district__revenue: false,
              k12district__reduced_lunch: false,
              k12district__reduced_lunch_perc: false,
              k12district__free_lunch: false,
              k12district__free_lunch_perc: false,
              k12district__uncategorized_lunch: false,
              k12district__uncategorized_lunch_perc: false,
              deal_count: false,
              note_count: false,
              distributors: false,
            }}
            setTotalRowsCount={setK12Count}
          />
        </FBPageTableContainer>
      ),
      'healthcare-hospitals': (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <HospitalsTable
            pageParamName={`${subCategoryActive?.slug}-page`}
            defaultColumnVisibility={{ city: false, country: false }}
            tableKey={HOSPITALS_TABLE_KEY}
            setTotalRowsCount={setHospitalCount}
          />
        </FBPageTableContainer>
      ),
      'healthcare-nursinghomes': (
        <FBPageTableContainer style={{ height: TABLE_CONTAINER_HEIGHT }}>
          <NursingHomesTable
            pageParamName={`${subCategoryActive?.slug}-page`}
            defaultColumnVisibility={{ city: false, country: false }}
            tableKey={NURSING_HOMES_TABLE_KEY}
            setTotalRowsCount={setNursingHomeCount}
          />
        </FBPageTableContainer>
      ),
    }

    return (
      <>
        {restaurantsTabsSelected && (
          <>
            <LTVProcessingBanner />
            {tableBySlugType['restaurants-bars']}
          </>
        )}

        {enableCnUTable &&
          universitiesTabSelected &&
          tableBySlugType['education-cu']}

        {enableK12Table && k12selected && tableBySlugType['education-k-12']}

        {enableHospitals &&
          hospitalsTabSelected &&
          tableBySlugType['healthcare-hospitals']}

        {enableNursingHomes &&
          nursingHomesTabSelected &&
          tableBySlugType['healthcare-nursinghomes']}
      </>
    )
  }, [
    unmatched,
    subCategoryActive,
    categoryActive,
    universitiesTabSelected,
    k12selected,
    chainsTabSelected,
    hospitalsTabSelected,
    enableHospitals,
    enableK12Table,
    enableCnUTable,
    enableNursingHomes,
    restaurantsTabsSelected,
  ])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!categoryActive) {
    return <NotFoundCard />
  }

  return (
    <FBPage
      headerChildren={
        <TabsWithDropdown
          tabs={mainTabs}
          selectedTabKey={categoryActive.slug}
          selectedDropdownItemKey={subCategoryActive?.slug ?? ''}
          setSelectedTab={(value: OpportunityDropdownItemValue) => {
            const url = `/opportunities/${value.category}/${value.subCategory}/`
            setLastOppVisited(url)
            navigate(url)
          }}
        />
      }
    >
      <Header>
        <TitleRow>
          <Title>
            {categoryActive?.name} Opportunities:{' '}
            <b>{subCategoryActive?.name}</b>
          </Title>
          <TabsContainer>
            <OpportunitiesTabs
              companyTypeSlug={subCategoryActive?.slug}
              unmatched={unmatched}
              setUnmatched={setUnmatched}
            />
          </TabsContainer>
        </TitleRow>
        {renderStats()}
      </Header>
      {tables}
    </FBPage>
  )
}

interface DoorsChainTableProps {
  setPlacesSummary?: (value: React.SetStateAction<PlacesSummary>) => void
  setChainsSummary?: (value: React.SetStateAction<ChainsSummary>) => void
  noPaddingTop?: boolean
}

function DoorChainTable(props: DoorsChainTableProps) {
  const { doorsTabSelected, chainsTabSelected } = useOpportunitiesContext()

  return (
    <>
      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={!doorsTabSelected}
      >
        <DoorsTable
          setDoorsSummary={props.setPlacesSummary}
          tableKey={DOORS_TABLE_KEY}
          defaultColumnVisibility={{
            zipcode: false,
            total_ltv: false,
            brand_ltv: false,
            revenue_ltv: false,
            one_year_total_value: false,
            taro: false,
            tabo: false,
            pounds_per_year: false,
            instagram_following: false,
            instagram_posts: false,
            dma: false,
            menu_url: false,
            menu_size: false,
            google_place_url: false,
            note_count: false,
            deal_count: false,
            sales_stages: false,
            account_owners: false,
            tags: false,
            distributors: false,
            menu_ingredients: false,
            full_address: false,
            contact_count: false,
            country: false,
          }}
        />
      </FBPageTableContainer>

      <FBPageTableContainer
        style={{ height: TABLE_CONTAINER_HEIGHT }}
        hide={!chainsTabSelected}
      >
        <ChainsTable
          defaultColumnVisibility={{
            total_ltv: false,
            brand_ltv: false,
            revenue_ltv: false,
            one_year_total_value: false,
            taro: false,
            tabo: false,
            pounds_per_year: false,
            note_count: false,
            deal_count: false,
            sales_stages: false,
            account_owners: false,
            tags: false,
            distributors: false,
            median_hhi: false,
            pop_density: false,
            hh_gt100k: false,
            reputation_data: false,
            michelin_stars_count: false,
            instagram_following: false,
            instagram_posts: false,
            instagram_text_percentile: false,
            contact_count: false,
            menu_size: false,
            countries_summary: false,
          }}
          tableKey={CHAINS_TABLE_KEY}
          setChainsSummary={props.setChainsSummary}
        />
      </FBPageTableContainer>
    </>
  )
}

export function OpportunitiesPage() {
  return (
    <OpportunitiesProvider>
      <Opportunities />
    </OpportunitiesProvider>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
`

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  align-items: 'start';
`

export const Title = styled.h1`
  font-size: 24px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 12px;
`

export const TabsContainer = styled.div`
  position: absolute;
  bottom: -12px;
  width: 100%;
`
