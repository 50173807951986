import { ColumnDef } from '@tanstack/react-table'
import { DataTable, DataTableProvider } from 'components/DataTable'
import { DataTableContainer } from 'components/DataTable/UI'
import { format } from 'date-fns'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { useGetRebateClaims, RebateClaim } from './tradespend_api'
import { formatUsd } from 'utils/formatting'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import { useNavigate } from 'react-router-dom'
import { RebateStatusPill } from './RebateStatusPill'

const PAGE_SIZE = 100
const TABLE_KEY = 'rebate_claims'

interface RebateClaimsTableProps {
  limit?: number
  isPaginationEnabled?: boolean
}

export function RebateClaimsTableComponent({
  limit = PAGE_SIZE,
  isPaginationEnabled = true,
}: RebateClaimsTableProps) {
  const navigate = useNavigate()
  const [pagination, setPagination] = usePaginationURLParams(limit)

  const { data, isLoading } = useGetRebateClaims({
    page: isPaginationEnabled ? pagination.pageIndex + 1 : 1,
    limit,
  })

  const columns: ColumnDef<RebateClaim>[] = [
    {
      accessorKey: 'created',
      header: 'Created',
      cell: ({ row }) => format(new Date(row.original.created), 'MMM d, yyyy'),
    },
    {
      accessorKey: 'company_name',
      header: 'Operator',
    },
    {
      accessorKey: 'products',
      header: 'Products',
      cell: ({ row }) => row.original.products.map((p) => p.name).join(', '),
    },
    {
      accessorKey: 'offer_name',
      header: 'Offer',
      cell: ({ row }) => (
        <span
          className="text-blue-600 hover:text-blue-800 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/rebates/offer/${row.original.offer_id}/details`)
          }}
        >
          {row.original.offer_name}
        </span>
      ),
    },
    {
      accessorKey: 'payout_amount',
      header: 'Amount',
      cell: ({ row }) => formatUsd(row.original.payout_amount || 0),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => <RebateStatusPill status={row.original.status} />,
    },
  ]

  return (
    <DataTableContainer>
      <DataTable
        data={data?.results || []}
        columns={columns}
        tableKey={TABLE_KEY}
        isPaginationEnabled={isPaginationEnabled}
        paginationOptions={
          isPaginationEnabled
            ? {
                pageCount: Math.ceil((data?.count || 0) / limit),
                pagination,
                setPagination,
                isPaginationLoading: isLoading,
              }
            : undefined
        }
        loading={isLoading}
      />
    </DataTableContainer>
  )
}

export function RebateClaimsTable(props: RebateClaimsTableProps) {
  return (
    <ColumnsStoreProvider tableKey={TABLE_KEY}>
      <DataTableProvider tableKey={TABLE_KEY}>
        <RebateClaimsTableComponent {...props} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
