import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { Badge } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { DataTable, DataTableProvider } from '../../components/DataTable'
import { DataTableContainer } from '../../components/DataTable/UI'
import { IColumnVisibility } from '../../components/DataTable/types'
import apiService from '../../services/api'
import { ColumnsStoreProvider } from '../../stores/ColumnsStore/ColumnsStoreProvider'
import { RebateOffer } from './tradespend_api'
import { formatInteger, formatUsd } from '../../utils/formatting'
import { usePaginationURLParams } from '../../utils/usePaginationURLParams'

const TABLE_KEY = 'rebates-table'
const PAGE_SIZE = 20

function RebateOffersTableComponent(props: {
  campaignId?: number
  tableKey: string
}) {
  const api = apiService()
  const navigate = useNavigate()
  const abortController = new AbortController()
  const [pagination, setPagination] = usePaginationURLParams(PAGE_SIZE)

  const { data: rebateData, isFetching } = api.useGetRebateOffers({
    campaignId: props.campaignId,
    signal: abortController.signal,
    page: pagination.pageIndex + 1,
    limit: PAGE_SIZE,
  })

  const columns = useMemo<ColumnDef<RebateOffer, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 120,
        cell: (info) => (
          <Badge
            bg={
              info.getValue() === 'PUBLISHED'
                ? 'success'
                : info.getValue() === 'PENDING'
                  ? 'warning'
                  : 'secondary'
            }
          >
            {info.getValue()}
          </Badge>
        ),
      },
      {
        accessorKey: 'scope',
        header: 'Scope',
        size: 120,
        cell: (info) => <Badge bg="info">{info.getValue()}</Badge>,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 150,
        cell: (info) => info.getValue().replace(/_/g, ' '),
      },
      {
        accessorKey: 'total_enrollments',
        header: 'Enrollments',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'total_claims',
        header: 'Total Claims',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'pending_validation_claims',
        header: 'Pending',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'invalid_claims',
        header: 'Invalid',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'paid_claims',
        header: 'Paid',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'unpaid_valid_claims',
        header: 'Unpaid Valid',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'total_validated_units',
        header: 'Total Units',
        size: 120,
        cell: (info) => formatInteger(info.getValue()),
      },
      {
        accessorKey: 'total_validated_payout',
        header: 'Total Payout',
        size: 150,
        cell: (info) => formatUsd(info.getValue()),
      },
      {
        accessorKey: 'valid_from',
        header: 'Valid From',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'valid_to',
        header: 'Valid To',
        size: 150,
        cell: (info) => format(new Date(info.getValue()), 'MMM d, yyyy'),
      },
      {
        accessorKey: 'product_names',
        header: 'Products',
        size: 200,
      },
    ],
    []
  )

  return (
    <DataTableContainer>
      <DataTable
        loading={isFetching}
        stickyLastColumn={true}
        data={rebateData?.results ?? []}
        columns={columns}
        tableKey={props.tableKey}
        onRowClick={(row) =>
          navigate(`/rebates/offer/${row.original.id}/details`)
        }
        isPaginationEnabled={true}
        paginationOptions={{
          pageCount: Math.ceil((rebateData?.count || 0) / PAGE_SIZE),
          pagination,
          setPagination,
          isPaginationLoading: isFetching,
        }}
      />
    </DataTableContainer>
  )
}

export function RebateOffersTable(
  props: { campaignId?: number } & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={TABLE_KEY}
      defaultColumnVisibility={defaultColumnVisibility}
    >
      <DataTableProvider tableKey={TABLE_KEY}>
        <RebateOffersTableComponent tableKey={TABLE_KEY} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}
