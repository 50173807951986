import { Control, useWatch, useForm, UseFormSetValue } from 'react-hook-form'
import { Modal } from 'components/UI/Modal/Modal'
import { RadioButtonSelect } from 'components/FormUtils/RadioButtonSelect'
import { Calendar } from 'lucide-react'
import { format } from 'date-fns'
import { DateRangePicker } from 'components/FormUtils/DateRangePicker'
import { useState, useEffect, useMemo } from 'react'

interface DateRangeValue {
  type: 'quarter' | 'half' | 'custom'
  quarter?: string
  half?: string
  year?: string
  dateRange?: {
    from: Date
    to: Date
  }
}

interface FormValues {
  showValue: 'claims' | 'amount'
  dateRange: DateRangeValue
  showAllClaims: 'all' | 'select'
  chartType: 'stacked' | 'line'
}

interface TempFormValues {
  type: 'quarter' | 'half' | 'custom'
  quarter?: string
  half?: string
  year?: string
  dateRange?: {
    from: Date
    to: Date
  }
}

interface Props {
  control: Control<FormValues>
  name: 'dateRange'
  setValue: UseFormSetValue<FormValues>
}

export function DateRangeSelect({ control, name, setValue }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const value = useWatch({ control, name }) as DateRangeValue
  const tempForm = useForm<TempFormValues>({
    defaultValues: value,
  })

  // Generate years array (current year + past 2 years)
  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear()
    return [0, 1, 2].map((offset) => ({
      label: String(currentYear - offset),
      key: String(currentYear - offset),
      icon: Calendar,
    }))
  }, [])

  // Reset temp form when modal opens
  useEffect(() => {
    if (isOpen) {
      tempForm.reset(value)
    }
  }, [isOpen])

  const handleApply = () => {
    // Get the current values from the temp form
    const tempValue = tempForm.getValues()

    // Update the parent form using setValue
    setValue(name, tempValue, {
      shouldValidate: true,
      shouldDirty: true,
    })

    setIsOpen(false)
  }

  const getDisplayText = () => {
    if (value.type === 'quarter' && value.quarter) {
      return `Q${value.quarter} ${value.year}`
    }
    if (value.type === 'half' && value.half) {
      return `H${value.half} ${value.year}`
    }
    if (
      value.type === 'custom' &&
      value.dateRange?.from &&
      value.dateRange?.to
    ) {
      return `${format(value.dateRange.from, 'MMM d, yyyy')} - ${format(
        value.dateRange.to,
        'MMM d, yyyy'
      )}`
    }
    return 'Select Date Range'
  }

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="inline-flex items-center space-x-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500"
      >
        <Calendar className="w-[21px] h-[21px]" />
        <span className="h-[21px] leading-[21px]">{getDisplayText()}</span>
      </button>

      <Modal
        open={isOpen}
        onOpenChange={setIsOpen}
        title="Select Date Range"
        acceptButtonText="Apply"
        onAccept={handleApply}
      >
        <div className="space-y-6">
          <RadioButtonSelect
            control={tempForm.control}
            name="type"
            options={[
              {
                label: 'Quarter',
                key: 'quarter',
                icon: Calendar,
              },
              {
                label: 'Half',
                key: 'half',
                icon: Calendar,
              },
              {
                label: 'Custom',
                key: 'custom',
                icon: Calendar,
              },
            ]}
          />

          {tempForm.watch('type') === 'quarter' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Quarter
                </label>
                <RadioButtonSelect
                  control={tempForm.control}
                  name="quarter"
                  options={[
                    {
                      label: 'Q1',
                      key: '1',
                      icon: Calendar,
                    },
                    {
                      label: 'Q2',
                      key: '2',
                      icon: Calendar,
                    },
                    {
                      label: 'Q3',
                      key: '3',
                      icon: Calendar,
                    },
                    {
                      label: 'Q4',
                      key: '4',
                      icon: Calendar,
                    },
                  ]}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Year
                </label>
                <RadioButtonSelect
                  control={tempForm.control}
                  name="year"
                  options={yearOptions}
                />
              </div>
            </div>
          )}

          {tempForm.watch('type') === 'half' && (
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Half
                </label>
                <RadioButtonSelect
                  control={tempForm.control}
                  name="half"
                  options={[
                    {
                      label: 'H1',
                      key: '1',
                      icon: Calendar,
                    },
                    {
                      label: 'H2',
                      key: '2',
                      icon: Calendar,
                    },
                  ]}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Year
                </label>
                <RadioButtonSelect
                  control={tempForm.control}
                  name="year"
                  options={yearOptions}
                />
              </div>
            </div>
          )}

          {tempForm.watch('type') === 'custom' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Date Range
              </label>
              <DateRangePicker
                control={tempForm.control}
                name="dateRange"
                className="w-full"
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
