export const OWNERSHIP_TYPE_OPTIONS = [
  { label: 'Corp - For Profit', value: 'For Profit - Corporation' },
  {
    label: 'Partnership - For Profit',
    value: 'For Profit - Partnership',
  },
  {
    label: 'Individual - For Profit',
    value: 'For Profit - Individual',
  },
  {
    label: 'LLC - For Profit',
    value: 'For Profit - Limited Liability Company',
  },
  { label: 'Corp - Non Profit', value: 'Non Profit - Corporation' },
  {
    label: 'Church - Non Profit',
    value: 'Non Profit - Church Related',
  },
  { label: 'Other - Non Profit', value: 'Non Profit - Other' },
  { label: 'State Govt', value: 'Government - State' },
  { label: 'Federal Govt', value: 'Government - Federal' },
  { label: 'City Govt', value: 'Government - City' },
  { label: 'County Govt', value: 'Government - County' },
  {
    label: 'City/County Govt',
    value: 'Government - City/County',
  },
  {
    label: 'Hospital District',
    value: 'Government - Hospital District',
  },
]

export const PROVIDER_TYPE_OPTIONS = [
  { label: 'Medicare & Medicaid', value: 'Medicare and Medicaid' },
  { label: 'Medicare', value: 'Medicare' },
  { label: 'Medicaid', value: 'Medicaid' },
]
