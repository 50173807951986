import { DataTypeColumn } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { EmailMessage } from 'models/emailIntegration'

type CustomFields = ''

export const EMAILS_COLUMNS = {
  outbound: {
    header: 'Type',
    tooltipName: 'outbound',
    group: 'Email',
  },
  related_contacts: {
    header: 'Related Contacts',
    tooltipName: 'related_contacts',
    group: 'Email',
  },
  subject: {
    header: 'Subject',
    tooltipName: 'subject',
    group: 'Email',
  },
  snippet: {
    header: 'Preview',
    tooltipName: 'snippet',
    group: 'Email',
  },
  date: {
    header: 'Date',
    tooltipName: 'date',
    group: 'Email',
  },
} satisfies Partial<Record<keyof EmailMessage | CustomFields, DataTypeColumn>>
